<template>
   <el-dialog
    append-to-body
    title="选择盘点差异货品"
    top="10vh"
    width="1200px"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    @close="handleClose">
    <section class="select-differ">
      <el-form inline size="small" label-position="top">
        <el-form-item label="款式分类">
          <el-cascader
              placeholder="请选择款式分类"
              v-model="queryForm.designId"
              clearable
              collapse-tags
              filterable
              :options="designOptions"
              :props="{
                  multiple: true,
                  checkStrictly: true,
                  emitPath: false,
                  value: 'id',
                  label: 'categoryName',
                  children: 'child'
              }"
              :show-all-levels="false"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="风格分类">
          <el-cascader
              placeholder="请选择风格分类"
              v-model="queryForm.styleId"
              clearable
              collapse-tags
              filterable
              :options="styleOptions"
              :props="{
                  multiple: true,
                  checkStrictly: true,
                  emitPath: false,
                  value: 'id',
                  label: 'categoryName',
                  children: 'child'
              }"
              :show-all-levels="false"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="时间分类">
          <el-cascader
              placeholder="请选择时间分类"
              v-model="queryForm.timeId"
              clearable
              collapse-tags
              filterable
              :options="timeOptions"
              :props="{
                  multiple: true,
                  checkStrictly: true,
                  emitPath: false,
                  value: 'id',
                  label: 'categoryName',
                  children: 'child'
              }"
              :show-all-levels="false"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="货号/简码">
          <el-input clearable placeholder="请输入货号/简码" v-model="queryForm.searchCode"/>
        </el-form-item>
        <el-form-item>
          <el-button class="btn-search" type="primary" size="small" @click="search">查 询</el-button>
        </el-form-item>
      </el-form>
      <section>
       <grid-manager :option="gridOption"></grid-manager>
      </section>
    </section>
    <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="selectDiff">确 定</el-button>
    </span>
    </el-dialog>
</template>

<script>
import {queryInventoryDifferenceGoodsNoList} from '@/libs/http/modules/stock'
import {queryAllByType} from "@/libs/http/api";
export default {
     props: {
        rowData: {
            type: Object,
            default: () => ({})
        }
    },
    data(){
      return {
        dialogVisible:false,
        gridOption: {
          gridManagerName: "selectDiff-table",
          supportAjaxPage: true,
          firstLoading: true,
          isCombSorting: true,
          sortUpText:'asc',
          sortDownText:'desc',
          sortMode:'single',
          supportCheckbox: true,
          supportAutoOrder: false,
          disableBorder: false,
          disableLine: false,
          checkboxConfig: {
              fixed: 'left',
              useRowCheck: true,//点击选择
              useRadio: true, //单选
          },
          columnData: [
            {
                key: 'goodsNo',
                text: '货号',
                align: 'center',
            },
            {
              key: 'goodsName',
              text: '货品名称',
              align: 'center',
            },
            {
              key: 'goodsCode',
              text: '简码',
              align: 'center',
            },
            {
              key: 'color',
              text: '颜色',
              align: 'center',
            },
            {
                key: 'inventoryNumber',
                text: '盘点数量',
                align: 'center'
            },
            {
                key: 'stockNumber',
                text: '库存数量',
                align: 'center',
            },
            {
                key: 'differenceNumber',
                text: '差异数量',
                align: 'center',
                sorting: '',
            },
            {
              key: 'retailPrice',
              text: '零售价',
              align: 'center',
            }
          ],
          height:'55vh',
          dataKey: 'items',
          totalsKey: 'totalItem',
          ajaxData: this.fetch,
          ajaxError: this.ajaxError
        },
        queryForm: {
          searchCode:'',
          designId: '',
          styleId: '',
          timeId: ''
        },
        designOptions: [],
        styleOptions: [],
        timeOptions: []
      }
    },
    created(){
      this.dialogVisible = true
      this.fetchQuerytree(0)
      this.fetchQuerytree(1)
      this.fetchQuerytree(2)
    },
    methods:{
        handleClose(){
            this.dialogVisible = false
            this.$emit('close')
        },
        fetch(params) {
            params.sortData.totalDifferenceNumber = params.sortData.differenceNumber
            let reqData = {
                recordInfoId:this.rowData.id,
                page: params.pageData.cPage,
                pageSize: params.pageData.pSize,
                deptIdList: [this.$cache.local.get('deptId')],
                goodsNo: this.queryForm.searchCode || '',
                goodsCode: this.queryForm.searchCode || '',
                barCode: this.queryForm.searchCode || '',
                sortData:{}
            }
            let categoryIds = []
            if(this.queryForm.designId){
              categoryIds = [...categoryIds, ...this.queryForm.designId]
            }
            if(this.queryForm.styleId){
              categoryIds = [...categoryIds, ...this.queryForm.styleId]
            }
            if(this.queryForm.timeId){
              categoryIds = [...categoryIds, ...this.queryForm.timeId]
            }
            if(categoryIds && categoryIds.length){
              reqData['categoryIds'] = categoryIds
            }
            reqData.sortData.totalDifferenceNumber = params.sortData.differenceNumber
            return queryInventoryDifferenceGoodsNoList(reqData);
        },
        search(){
             GridManager.refreshGrid(this.gridOption.gridManagerName,true);
        },
        ajaxError(e){
            this.$message({type: 'error', message: e, duration: 2000, showClose: true})
        },
        selectDiff(){
            let list = GridManager.getCheckedData(this.gridOption.gridManagerName)
            this.$emit('selectDiffData',list)
        },
      fetchQuerytree(type) {
        queryAllByType({
          type: type
        }).then(res=>{
          if(type === 0){
            this.designOptions = res
          }
          if(type === 1){
            this.styleOptions = res
          }
          if(type === 2){
            this.timeOptions = res
          }
        }).catch(error=>{
          this.$message({type: 'error', message: error, duration: 2000, showClose: true})
        })
      },
      recursiveFilter(arr) {
        const data = arr
        data.forEach((item) => {
          if (item.pojoList && item.pojoList.length) {
            this.recursiveFilter(item.pojoList)
          } else {
            delete item.pojoList
          }
        })
        return data
      },
    },
}
</script>
<style lang="scss">
.select-differ{
  .el-form-item--small .el-form-item__label{
    line-height: 10px;
  }
  .btn-search{
    margin-top: 22px;
    margin-left: 30px;
  }
}
</style>