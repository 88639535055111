<template>
	<div class="container">
		<stockTopMenu windowId="deptStock" module="stock">
			<div slot="descript" class="row" style="margin-left: 300px;">
				<div class="row align-center cursor tab-item" style="margin-left: 100px;"
					 @click="$router.push('/deptStock');">
					<p>
						<img src="@/assets/images/tabIcon/start.png"
							 style="width:18px;height:18px;margin-right: 10px;float: left;margin-top: 2px;">
						<span>开始盘点</span>
					</p>
				</div>
				<div class="row align-center cursor tab-item"
					 style="margin-left: 100px;color: #067CF2;border-bottom: 4px solid #067CF2;margin-top: 20px;">
					<p style="padding-bottom: 15px;">
						<img src="@/assets/images/tabIcon/record-select.png"
							 style="width:18px;height:18px;margin-right: 10px;float: left;margin-top: 2px;">
						<span>盘点记录</span>
					</p>
				</div>
			</div>
		</stockTopMenu>
		<searchForm :options="options" @searchHandel="searchHandel"></searchForm>
		<section class="record-main"
				 v-loading="loading"
				 element-loading-text="请稍后，全力加载中..."
				 element-loading-spinner="el-icon-loading">
			<div class="record-table">
				<grid-manager
					v-if="gridOption && gridOption.ajaxData"
					ref="grid"
					:option="gridOption"
				></grid-manager>
			</div>
		</section>
		<multiWindow
			@close="closeDialog"
			ref="multiWindowRef"
			:moduleList="moduleList"
			:hasAdd="hasAdd"
			@hasAddChange="hasAddChange"
		></multiWindow>
		<singleRediscVue v-if="singleReSetDialog" ref="singleReSetRef" :modalParams="modalParams"
						 @close="close"></singleRediscVue>
	</div>
</template>

<script>
import {changeRecordState, queryDeptInventory} from '@/libs/http/modules/stock'
import stockTopMenu from "@/components/stockTopMenu.vue"
import searchForm from "@/components/searchForm.vue"
import multiWindow from '@/components/multiWindow/index.vue'
import {dialogConfig} from './../components/dialogConfig'
import {differConfig} from './../components/differConfig'
import {differDetail} from '../components/differDetailConfig.js'
import singleRediscVue from './components/singleRedisc.vue'

export default {
	components: {
		stockTopMenu,
		searchForm,
		singleRediscVue,
		multiWindow
	},
	data() {
		return {
			options: {
				searchItem: [
					{
						id: '1',
						type: 'input',
						size: 'small',
						clearable: true,
						placeholder: '请输入盘点编号',
						label: '盘点编号',
						value: '',
						resField: 'serialNumber'
					},
					{
						id: '2',
						type: 'input',
						size: 'small',
						clearable: true,
						placeholder: '请输入盘点单名称',
						label: '盘点单名称',
						value: '',
						resField: 'serialName'
					},
					{
						id: '3',
						type: 'daterange',
						size: 'small',
						clearable: true,
						placeholder: '请选择创建时间',
						label: '创建时间',
						value: '',
						resField: 'createTime'
					},
					{
						id: '4',
						type: 'input',
						size: 'small',
						clearable: true,
						placeholder: '请输入创建人名称',
						label: '创建人',
						value: '',
						resField: 'userName'
					},
					{
						id: '5',
						type: 'select',
						size: 'small',
						clearable: true,
						placeholder: '请选择',
						label: '状态',
						value: '',
						resField: 'state',
						list: [
							{name: '正在盘点', value: '1'},
							{name: '已作废', value: '2'},
							{name: '已提交', value: '3'},
							{name: '过账审核', value: '10'},
							{name: '已过账', value: '7'},
						]
					}
				],
				// 确认按钮
				sureBtnVisable: true,
				sureBtnType: 'primary',
				sureBtnSize: 'small',
				sureBtnText: '查询',
				//重置按钮
				restBtnVisable: true,
				restBtnType: '',
				restBtnSize: 'small',
				restBtnText: '重置',
			},
			queryForm: {},
			loading: false,
			gridOption: {
				gridManagerName: "stock-record",
				supportAjaxPage: true,
				firstLoading: true,
				isCombSorting: false,
				supportCheckbox: false,
				supportAutoOrder: false,
				disableBorder: true,
				disableLine: true,
				columnData: [
					{
						key: 'deptName',
						text: '部门名称',
						align: 'center',
						width: '120px'
					},
					{
						key: 'inventoryName',
						text: '盘点单名称',
						align: 'center',
						width: '250px'
					},
					{
						key: 'seq',
						text: '记录编号',
						align: 'center',
						width: '150px'
					},
					{
						key: 'typeDesc',
						text: '盘点类型',
						align: 'center'
					},
					{
						key: 'stateDesc',
						text: '状态',
						align: 'center',
						width: '180px',
						template: (row) => {
							return `
                <span :style="{color: row.state === '1' ? '#067CF2' : row.state === '2' ? '#F8475F' : row.state === '3' ? '#43D268' : row.state === '7' ? '#BD73D3' : '#FF9000'}">{{row.stateDesc}}</span>
              `
						}
					},
					{
						key: 'userName',
						text: '创建人',
						width: '120px',
						align: 'center'
					},
					{
						key: 'createTime',
						text: '创建时间',
						align: 'center',
						width: '180px'
					},
					{
						key: 'finishTime',
						text: '完盘时间',
						align: 'center',
						width: '180px'
					},
					{
						key: 'reviewTime',
						text: '审核时间',
						align: 'center',
						width: '180px'
					},
					{
						key: 'detailCount',
						text: '明细单数',
						align: 'center'
					},
					{
						key: 'goodsNoCount',
						text: '款数量',
						align: 'center'
					},
					{
						key: 'goodsCount',
						text: '货品数量',
						align: 'center'
					},
					{
						key: 'unrecognizedCount',
						text: '无法识别数量',
						align: 'center'
					},
					{
						key: "action",
						text: "操作",
						width: "350px",
						align: "center",
						fixed: "right",
						template: () => {
							return `
                <el-button v-if="['1', '3'].includes(row.state)" size="mini" type="text" @click="changeState(row, '2')">本单作废</el-button>
                <el-button v-if="row.state === '2'" size="mini" type="text" @click="changeState(row, '8')">恢复本单</el-button>
                <el-button v-if="row.state === '3'" size="mini" type="text" @click="ReSetOne(row)">单款重盘</el-button>
                <el-button v-if="row.state === '1'" size="mini" type="text" @click="changeState(row, '3')">完成盘点</el-button>
                <el-button v-if="row.state === '7'" size="mini" type="text" @click="showDifferDetail(row)">差异明细</el-button>
                <el-button v-if="['3', '10', '7'].includes(row.state)" size="mini" type="text" @click="showDiffer(row)">对比差异</el-button>
                <el-button size="mini" type="text" @click="showDetail(row)">查看明细</el-button>
              `;
						},
					},
				],
				dataKey: 'items',
				totalsKey: 'totalItem',
				ajaxData: this.fetch,
				ajaxError: this.ajaxError
			},
			modalParams: null,
			singleReSetDialog: false,
			differConfig,
			dialogConfig,
			differDetail,
			moduleList: [], //存放最小化列表
			hasAdd: 'N', //判断是否有新增
		};
	},
	methods: {
		searchHandel(e) {
			this.queryForm = e;
			GridManager.refreshGrid(this.gridOption.gridManagerName);
		},
		fetch(params) {
			let reqData = {
				serialNumber: this.queryForm['serialNumber'],
				name: this.queryForm['serialName'],
				userName: this.queryForm['userName'],
				state: this.queryForm['state'],
				page: params.pageData.cPage,
				pageSize: params.pageData.pSize,
				deptIdList: [this.$cache.local.get('deptId')]
			}
			if (this.queryForm && this.queryForm.createTime) {
				reqData['createStartTime'] = this.queryForm.createTime[0]
				reqData['createEndTime'] = this.queryForm.createTime[1]
			}
			return queryDeptInventory(reqData);
		},
		ajaxError(e) {
			this.$message({type: 'error', message: e, duration: 2000, showClose: true})
		},
		changeState(row, type) {
			let str = "";
			if (type == 2) {
				str = "是否将该盘点单作废？";
			}
			if (type == 3) {
				str = "是否将该盘点单标记完成，完成后将不能再继续添加明细单？";
			}
			if (type == 8) {
				str = "是否将该盘点单恢复正常？";
			}
			this.$confirm(str)
				.then(() => {
					this.loading = true;
					changeRecordState({
						seq: row.seq,
						state: type
					}).then(data => {
						this.$message({type: 'success', message: '操作成功', duration: 2000, showClose: true})
						GridManager.refreshGrid(this.gridOption.gridManagerName);
						this.loading = false;
					}).catch(error => {
						this.loading = false;
						this.$message({type: 'error', message: error, duration: 2000, showClose: true})
					})
				})
				.catch(() => {
				});
		},
		showDetail(row) {
			let arrId = this.moduleList.map(item => {
				return item.id
			})
			if (row && !arrId.includes(row?.id)) {
				this.moduleList.push(this.dialogConfig.detailsDialog(row))
			}
			//如果已经打开就把最小化的恢复到正常
			if (arrId.includes(row?.id)) {
				for (let ele of this.moduleList) {
					if (ele.id == row.id) {
						this.$refs['multiWindowRef'].unfoldTag(ele)
					}
				}
			}
		},
		showDiffer(row) {
			let arrId = this.moduleList.map(item => {
				return item.id
			})
			if (row && !arrId.includes(row?.id)) {
				this.moduleList.push(this.differConfig.detailsDialog(row))
			}
			//如果已经打开就把最小化的恢复到正常
			if (arrId.includes(row?.id)) {
				for (let ele of this.moduleList) {
					if (ele.id == row.id) {
						this.$refs['multiWindowRef'].unfoldTag(ele)
					}
				}
			}
		},
		
		showDifferDetail(row) {
			let arrId = this.moduleList.map(item => {
				return item.id
			})
			if (row && !arrId.includes(row?.id)) {
				this.moduleList.push(this.differDetail.detailsDialog(row))
			}
			//如果已经打开就把最小化的恢复到正常
			if (arrId.includes(row?.id)) {
				for (let ele of this.moduleList) {
					if (ele.id == row.id) {
						this.$refs['multiWindowRef'].unfoldTag(ele)
					}
				}
			}
		},
		
		//单款重盘
		ReSetOne(row) {
			this.modalParams = row
			this.singleReSetDialog = true
		},
		//关闭单款重盘
		close(e) {
			this.singleReSetDialog = false
			if (e) {
				GridManager.refreshGrid(this.gridOption.gridManagerName);
			}
		},
		closeDialog(e, type) {
			this.moduleList.forEach(item => {
				if (item.id == e[1]?.id) {
					item.isminimize = true
				}
				if (!e[1] && item.id == '-1') {
					item.isminimize = true
				}
			})
			if (e[0] && e[0] !== 'close') {
				GridManager.refreshGrid(this.gridOption.gridManagerName, true)
			}
			if (e[0] === 'close' || e[0]) {
				this.$refs['multiWindowRef'].closeTag(e[1])
			}
		},
		hasAddChange(e) {
			this.hasAdd = e
		}
	},
};
</script>

<style lang="scss" scoped>
.container {
	height: 100vh;
	overflow: hidden;
}

.record-main {
	padding: 0 10px 10px;
	width: 100%;
	box-sizing: border-box;
	
	.record-table {
		height: calc(100vh - 180px);
		padding: 20px;
		background: #fff;
		box-sizing: border-box;
	}
}
</style>
<style>
[grid-manager-mock-thead] {
	background: #F5F7FA !important;
}

[gm-overflow-x="true"] [grid-manager-mock-thead] [fixed]:last-child:after {
	background: #F5F7FA;
}

[gm-overflow-x="true"] [grid-manager-mock-thead] [fixed] {
	background: #F5F7FA;
}

.table-wrap .table-header {
	background: #F5F7FA;
}

.record-table .table-wrap {
	height: calc(100vh - 220px) !important;
}
</style>