<template>
  <Zdialog
      width="90%"
      ref="zdialog"
      :title="'【盘点单】' + modalParams.seq"
      zDialogRef="z-dialog1"
      :show.sync="dialogVisible"
      :isConfirmClose="false"
      :isFooter="false"
      :modal="false"
      @close="close"
      @minimizeVal="minimizeVal">
    <section slot="body" style="margin: 0 20px;"
       v-loading="loading"
       element-loading-text="请稍后，全力加载中..."
       element-loading-spinner="el-icon-loading">
      <div class="stock-data">
        <el-descriptions class="margin-top" :column="2" size="mini" border style="font-size: 16px;width: 1000px;">
          <el-descriptions-item>
            <template slot="label">
              盘点单名称
            </template>
            {{modalParams.inventoryName}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              创建时间
            </template>
            {{modalParams.createTime}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              盘点类型
            </template>
            {{modalParams.typeDesc}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              完盘时间
            </template>
            {{modalParams.finishTime}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              店铺
            </template>
            {{modalParams.deptName}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              审核时间
            </template>
            {{modalParams.reviewTime}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              状态
            </template>
            {{modalParams.stateDesc}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              创建人
            </template>
            {{modalParams.userName}}
          </el-descriptions-item>
        </el-descriptions>

        <template v-if="modalParams && modalParams.areaItemList">
          <div class="stock-total">
            <div class="tabs">
              <div :class="[stockTabs === '1' ? 'active-tab' : '']" @click="changeTabs('1')">盘点汇总</div>
              <div :class="[stockTabs === '2' ? 'active-tab' : '']" @click="changeTabs('2')">明细单</div>
            </div>
          </div>
          <div v-show="stockTabs === '1'">
            <div class="query">
              <div>
                <span>货号</span>
                <erp-selector
                    placeholder="请选择"
                    size="mini"
                    modelKey="goodsNo"
                    type="goodsNo"
                    ref="goodsNo"
                    v-model="totalQuery.goodsNo"
                    :multiple="true"
                ></erp-selector>
              </div>
              <div>
                <el-button type="primary" size="mini" :loading="loading" @click="refreshTotal">查 询</el-button>
              </div>
              <p class="row" style="color: red;font-size: 16px;font-weight: 500;">
                汇总：
                <span>盘点数量：{{totalInventoryNumber || '0'}}</span>
                <span style="margin-left: 20px;">盘点金额：{{totalInventoryPrice || '0.00'}}</span>
              </p>
            </div>
            <section>
              <grid-manager :option="totalOption"></grid-manager>
            </section>
          </div>
          <div v-show="stockTabs === '2'">
            <div class="row">
              <div class="detail-list">
                <el-form ref="form" size="mini" :model="detailQuery" inline style="margin-bottom: -30px;margin-top: 10px;">
                  <el-form-item label="创建人">
                    <el-input placeholder="请输入创建人名称" clearable v-model="detailQuery.createUser"></el-input>
                  </el-form-item>
                  <el-form-item label="货号">
                    <erp-selector
                      placeholder="请选择货号"
                      size="mini"
                      modelKey="goodsNo"
                      type="goodsNo"
                      ref="goodsNos"
                      v-model="detailQuery.goodsNos"
                      :multiple="true"
                    ></erp-selector>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" size="mini" @click="searchList">查 询</el-button>
                  </el-form-item>
                </el-form>
                <section style="margin-top: 20px;">
                  <grid-manager :option="detailOptions"></grid-manager>
                </section>
              </div>
              <div class="row-detail" v-if="rowItem">
                <div class="flex-item">
                  <div>
                    <span>明细编号</span>
                    <span :style="{color: rowItem.invoiceType === '2' ? '#8250df' : ''}">{{rowItem.serialNumber}}</span>
                  </div>
                  <div>
                    <span>创建人</span>
                    <span>{{rowItem.createUserName}}</span>
                  </div>
                  <div>
                    <span>创建时间</span>
                    <span>{{rowItem.createTime}}</span>
                  </div>
                  <div>
                    <span>状态</span>
                    <span :style="{color: rowItem.state === '0' ? '' : 'red'}">{{rowItem.state === '0' ? '正常' : '已作废'}}</span>
                  </div>
                  <div v-if="rowItem.description">
                    <span>备注</span>
                    <span>{{ rowItem.description }}</span>
                  </div>
                </div>
                <section class="detail-info">
                  <div class="query">
                    <div>
                      <span>货号</span>
                      <erp-selector
                          placeholder="请选择"
                          size="mini"
                          modelKey="goodsNo"
                          type="goodsNo"
                          ref="goodsNo"
                          v-model="detailQuery.goodsNo"
                          :multiple="true"
                      ></erp-selector>
                    </div>
                    <div>
                      <el-button type="primary" size="mini" :loading="loading" @click="searchDetail">查 询</el-button>
                    </div>
                  </div>
                  <grid-manager :option="gridOption"></grid-manager>
                </section>
                <div class="action-btn" v-if="['1'].includes(modalParams.state)">
                  <el-button class="button" v-if="rowItem.state === '0'" type="primary" size="mini" @click="detailVoid">作废</el-button>
                  <el-button class="button" v-else type="primary" size="mini" @click="detailRecove">恢复</el-button>
                </div>
              </div>
            </div>
          </div>
        </template>
        <el-empty v-else description="该盘点单还没有盘点数据" style="margin-top:100px;"></el-empty>
      </div>
    </section>
    <section slot="titleRight" class="dialog-top">
      <div class="btnTop">
        <template v-if="modalParams.state === '2'">
          <basic-button type="primary" :loading="loading" icon-class="draft" @click="recoverInvoice"> 恢复本单 </basic-button>
        </template>
        <template v-if="modalParams.state === '1' || modalParams.state === '3'">
          <basic-button type="primary" :loading="loading" icon-class="draft" @click="voidInvoice"> 本单作废 </basic-button>
          <basic-button v-if="modalParams.state === '1'" type="primary" :loading="loading" icon-class="submit" @click="finishInvoice"> 完成盘点 </basic-button>
        </template>
      </div>
    </section>
  </Zdialog>
</template>

<script>
import Zdialog from '@/components/ZDialog/index.vue'
import erpSelector from '@/components/ErpSelector/index'
import {
  changeDetailState,
  queryRecordGoods,
  queryRecordDetails,
  queryStockTotalList,
  changeRecordState
} from "@/libs/http/modules/stock";
export default {
  name: 'stockDetails',
  components: {
    Zdialog,
    erpSelector
  },
  props: {
    modalParams: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialogVisible: true,
      loading: false,
      stockTabs: '1',
      totalQuery: {
        goodsNo: ''
      },
      detailQuery: {
        createUser: '',
        goodsNo: '',
        goodsNos: ''
      },
      rowItem: null,
      gridOption: {
        gridManagerName: 'stockDetailList',
        supportAutoOrder: false,
        firstLoading: true,
        isCombSorting: false,
        lineHeight: '30px',
        height: '48vh',
        supportCheckbox: false,
        supportAjaxPage: false,
        useTrFocus: true,
        disableBorder: false,
        disableLine: false,
        columnData: [
          {
            key: 'goodsName',
            text: '货品名称'
          },
          {
            key: 'goodsNo',
            text: '货号'
          },
          {
            key: 'color',
            text: '颜色'
          },
          {
            key: 'size',
            text: '尺码'
          },
          {
            key: 'inventoryNumber',
            text: '盘点数量'
          },
          {
            key: 'tagPrice',
            text: '吊牌价'
          },
          {
            key: 'inventoryPrice',
            text: '盘点金额'
          },
        ],
        ajaxData: this.fetchList,
        summaryHandler: (data) => {
          let list = ['inventoryNumber', 'inventoryPrice']
          let obj = {
            goodsName: '汇总'
          }
          list.forEach((key) => {
            obj[key] = data.reduce((c, R) => c + Number(R[key] || 0), 0)
          })
          return obj
        }
      },
      totalOption: {
        gridManagerName: 'totalOptionList',
        supportAutoOrder: false,
        firstLoading: true,
        lineHeight: "26px",
        height: '60vh',
        isCombSorting: false,
        supportCheckbox: false,
        supportAjaxPage: true,
        pageSize: 50,
        sizeData: [100, 200, 500, 1000],
        useTrFocus: true,
        disableBorder: false,
        disableLine: false,
        columnData: [
          {
            key: 'goodsName',
            text: '货品名称'
          },
          {
            key: 'goodsNo',
            text: '货号'
          },
          {
            key: 'color',
            text: '颜色'
          },
          {
            key: 'size',
            text: '尺码'
          },
          {
            key: 'inventoryNumber',
            text: '盘点数量'
          },
          {
            key: 'tagPrice',
            text: '吊牌价'
          },
          {
            key: 'inventoryPrice',
            text: '盘点金额'
          },
        ],
        ajaxData: this.queryStockTt,
        summaryHandler: (data) => {
          let list = ['inventoryNumber', 'inventoryPrice']
          let obj = {
            goodsName: '本页汇总'
          }
          list.forEach((key) => {
            obj[key] = data.reduce((c, R) => c + Number(R[key] || 0), 0)
          })
          return obj
        }
      },
      detailOptions: {
        gridManagerName: 'detailOptionList',
        supportAutoOrder: false,
        firstLoading: true,
        lineHeight: "26px",
        height: '60vh',
        isCombSorting: false,
        supportCheckbox: false,
        supportAjaxPage: false,
        pageSize: 50,
        sizeData: [100, 200, 500, 1000],
        useTrFocus: true,
        disableBorder: false,
        disableLine: false,
        columnData: [
          {
            key: 'serialNumber',
            width: '150px',
            text: '明细单编号',
            template: () =>{
              return `
                <div :style="{color: row.invoiceType === '2' ? '#8250df !important' : ''}">{{row.serialNumber}}</div>
              `
            }
          },
          {
            key: 'inventoryNumber',
            text: '盘点数量'
          },
          {
            key: 'createUserName',
            width: '120px',
            text: '创建人'
          },
          {
            key: 'state',
            text: '状态',
            template: () =>{
              return `
                <div>
                    <span v-if="row.state === '0'">正常</span>
                    <span v-else style="color: red;">已作废</span>
                </div>
              `
            }
          },
          {
            key: 'createTime',
            width: '165px',
            text: '创建时间'
          },
          {
            key: 'description',
            width: '150px',
            text: '备注'
          }
        ],
        ajaxData: this.queryList,
        rowClick: this.rowClick,
        summaryHandler: (data) => {
          let list = ['inventoryNumber']
          let obj = {
            serialNumber: '汇总'
          }
          list.forEach((key) => {
            obj[key] = data.reduce((c, R) => c + Number(R[key] || 0), 0)
          })
          return obj
        }
      },
      totalInventoryNumber: '',
      totalInventoryPrice: ''
    }
  },
  methods: {
    close() {
      this.$emit('close', ['close', this.modalParams])
    },
    minimizeVal(e) {
      //是否是最小化，是的话就传给父组件
      if (e) {
        this.$emit('close', ['', this.modalParams])
      }
    },
    isMinimize() {
      this.$refs['zdialog'].minimize()
    },

    changeTabs(tabs){
      if(tabs === this.stockTabs) return
      this.stockTabs = tabs
    },
    recoverInvoice(){
      this.$confirm(`确定恢复该盘点单`, '提示').then(res=>{
        changeRecordState({
          state: '8',
          seq: this.modalParams.seq
        }).then(data=>{
          this.$emit('close', [true, this.modalParams])
          this.$message({type: 'success', message: '操作成功',duration:2000,showClose:true})
        }).catch(error=>{
          this.$message({type: 'error', message: error,duration:2000,showClose:true})
        })
      }).catch(error=>{})
    },
    voidInvoice(){
      this.$confirm(`确定作废该盘点单`, '提示').then(res=>{
        changeRecordState({
          state: '2',
          seq: this.modalParams.seq
        }).then(data=>{
          this.$emit('close', [true, this.modalParams])
          this.$message({type: 'success', message: '操作成功',duration:2000,showClose:true})
        }).catch(error=>{
          this.$message({type: 'error', message: error,duration:2000,showClose:true})
        })
      }).catch(error=>{})
    },
    finishInvoice(){
      this.$confirm(`确定将该盘点单标记为完成盘点`, '提示').then(res=>{
        this.loading = true
        changeRecordState({
          state: '3',
          seq: this.modalParams.seq
        }).then(data=>{
          this.loading = false
          this.$emit('close', [true, this.modalParams])
          this.$message({type: 'success', message: '操作成功',duration:2000,showClose:true})
        }).catch(error=>{
          this.loading = false
          this.$message({type: 'error', message: error,duration:2000,showClose:true})
        })
      }).catch(error=>{})
    },

    queryStockTt(res){
      const that = this;
      return new Promise(async (resolve, reject) => {
        try {
          let params = {
            id: that.modalParams.id,
            page: res.pageData.cPage,
            pageSize: res.pageData.pSize
          }
          if(that.totalQuery.goodsNo && that.totalQuery.goodsNo.length){
            params['goodsNo'] = that.totalQuery.goodsNo.join(' ')
          }
          let data = await queryStockTotalList(params);
          that.totalInventoryNumber = data.totalInventoryNumber
          that.totalInventoryPrice = data.totalInventoryPrice
          const tableData = {
            data: data.items,
            totals: Number(data.totalItem) || 0,
          };
          resolve(tableData);
        }catch(e) {
          this.$message({type: 'error', message: e, duration: 2000, showClose: true})
          reject("data err");
        }
      });
    },
    refreshTotal(){
      GridManager.refreshGrid(this.totalOption.gridManagerName, true)
    },
    searchList(){
      GridManager.refreshGrid(this.detailOptions.gridManagerName)
    },
    searchDetail(){
      GridManager.refreshGrid(this.gridOption.gridManagerName)
    },
    queryList(){
      return new Promise(async (resolve, reject) => {
        try {
          let reqdata = {
            id: this.modalParams.id
          }
          if(this.detailQuery.createUser){
            reqdata['createUserName'] = this.detailQuery.createUser
          }
          if(this.detailQuery.goodsNos && this.detailQuery.goodsNos.length){
            reqdata['goodsNos'] = this.detailQuery.goodsNos.join(' ')
          }
          let data = await queryRecordDetails(reqdata);
          const tableData = {
            data: data
          };
          resolve(tableData);
        }catch(e) {
          this.$message({type: 'error', message: e, duration: 2000, showClose: true})
          reject("data err");
        }
      });
    },
    rowClick(item){
      if(item && this.rowItem && (this.rowItem.id === item.id)) return
      if(item){
        this.rowItem = item
      }
      GridManager.refreshGrid(this.gridOption.gridManagerName)
    },
    fetchList(){
      return new Promise(async (resolve, reject) => {
        try {
          let params = {
            id: this.rowItem.id
          }
          if(this.detailQuery.goodsNo && this.detailQuery.goodsNo.length){
            params['goodsNo'] = this.detailQuery.goodsNo.join(' ')
          }
          let data = await queryRecordGoods(params);
          const tableData = {
            data: data
          };
          resolve(tableData);
        }catch (e) {
          this.$message({type: 'error', message: e, duration: 2000, showClose: true})
          reject("data err");
        }
      })
    },
    detailVoid(){
      this.$confirm(`确定将该明细单作废`, '提示').then(res=>{
        this.loading = true
        changeDetailState({
          state: '1',
          seq: this.rowItem.serialNumber
        }).then(res=>{
          this.rowItem.state = '1'
          this.loading = false
          this.$message({type: 'success', message: '操作成功',duration:2000,showClose:true})
          GridManager.refreshGrid(this.detailOptions.gridManagerName)
        }).catch(error=>{
          this.loading = false
          this.$message({type: 'error', message: error,duration:2000,showClose:true})
        })
      }).catch(error=>{})
    },
    detailRecove(){
      this.$confirm(`确定恢复该明细单`, '提示').then(res=>{
        this.loading = true
        changeDetailState({
          state: '0',
          seq: this.rowItem.serialNumber
        }).then(res=>{
          this.rowItem.state = '0'
          this.loading = false
          this.$message({type: 'success', message: '操作成功',duration:2000,showClose:true})
          GridManager.refreshGrid(this.detailOptions.gridManagerName)
        }).catch(error=>{
          this.loading = false
          this.$message({type: 'error', message: error,duration:2000,showClose:true})
        })
      }).catch(error=>{})
    }
  }
}
</script>

<style lang="scss" scoped>
.stock-total{
  margin-top: 50px;
  .tabs{
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    width: 200px;
    text-align: center;
    div{
      width: 50%;
      color: #000;
      border-left: 1px solid #ddd;
      height: 32px;
      line-height: 32px;
      cursor: pointer;
      &:first-child{
        border-left: none;
      }
      background: #fff;
    }
    .active-tab{
      color: #fff;
      background: rgb(7,124,242);
    }
  }
}

.query{
  display: flex;
  align-items: center;
  margin: 20px 0;
  div{
    display: flex;
    align-items: center;
    margin-right: 20px;
    span{
      width: 60px;
    }
  }
}

.detail-list{
  width: 700px;
}

.row-detail{
  margin-top:20px;
  background:#fafafa;
  padding:15px;
  position: relative;
  width: calc(100% - 720px);
  margin-left: 20px;
  .flex-item{
    display: flex;
    flex-wrap: wrap;
    div{
      width: calc(100% / 3);
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      span:first-child{
        width: 70px;
        color: #666;
      }
      span:last-child{
        color: #000;
      }
    }
  }
  .detail-info{
    >div{
      margin-bottom: 10px;
    }
  }
  .action-btn{
    position: absolute;
    right: 10px;
    top: 10px;
    .button{
      width: 100px;
    }
  }
}

.dialog-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 18px;
    color: #333;
    font-weight: bold;
  }
  div i {
    cursor: pointer;
    font-size: 22px;
    margin-left: 20px;
    float: right;
    margin-top: 8px;
  }
}
</style>