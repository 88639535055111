<template>
    <section>
        <section v-if="moduleList.length" style="border: 1px solid; display: none">
            <template v-for="item of moduleList">
                <component
                    :is="item.path"
                    :key="item.id"
                    @close="closeDialog"
                    :ref="item.ref"
                    :modalParams="item.modalParams"
                    :attrs="item.attrs"
                    @refresh="refresh(item)"
                >
                </component>
            </template>
        </section>
        <!-- 最小化列表 -->
        <section class="popover-class" v-if="moduleList.length">
            <el-popover placement="top" v-model="visible">
                <!-- <p>存放当前页最小化</p> -->
                <div>最小化窗口</div>
                <section class="tag-class">
                    <div
                        class="tagItem-class"
                        style="text-align: center; margin: 0"
                        v-for="item of moduleList"
                        :key="item.id"
                    >
                        <el-tag
                            closable
                            @close="closeTag(item)"
                            @click="unfoldTag(item)"
                            :effect="item.isminimize ? 'plain' : 'dark'"
                            >{{ item.tagName || '' }}</el-tag
                        >
                        <!-- <section v-if="item.isminimize">编辑</section> -->
                    </div>
                </section>
                <el-button
                    style="background: #fff200; border: none; width: 100px; box-shadow: 0 0 10px #067cf2; color: #000"
                    slot="reference"
                >
                    <svg-icon :icon-class="visible ? 'a-81' : 'a-80'" style="font-size: 18px; margin-right: 5px" />
                    {{ visible ? '收起' : '展开' }}</el-button
                >
            </el-popover>
        </section>
    </section>
</template>

<script>
import svgIcon from '@/components/SvgIcon/index'
export default {
    name: 'multiWindow',
    props: {
        moduleList: {
            type: Array,
            default: []
        },
        hasAdd: {
            type: String,
            default: 'N'
        }
    },
  components: { svgIcon },
    data() {
        return {
            // moduleList: [],
            visible: false
        }
    },

    methods: {
        //展开最小化详情
        unfoldTag(row) {
            for (let [index, item] of this.moduleList.entries()) {
                if (item.isminimize === false) {
                    this.$refs[item.ref][0].isMinimize()
                }
                if (row.id === item.id && item.isminimize === true) {
                    this.moduleList[index].isminimize = false
                    this.$refs[item.ref][0].isMinimize()
                }
            }
        },
        //关闭closeTag
        closeTag(row) {
            for (let [index, ele] of this.moduleList.entries()) {
                //说明是新增，没有行数据
                if (!row && ele.id === '-1') {
                    this.moduleList.splice(index, 1)

                    // this.hasAdd = 'N' 子组件不建议直接修改父组件的值
                    this.$emit('hasAddChange', 'N')
                    break
                }
                if (row?.id === ele.id) {
                    this.moduleList.splice(index, 1)
                    ele.id == '-1' ? this.$emit('hasAddChange', 'N') : ''
                    break
                }
            }
        },
        closeDialog(e) {
            this.$emit('close', e)
        },
         //刷新
         refresh(item) {
            item?.refreshList?.map((ele) => {
                this.$refs[item.ref][0][ele]()
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.popover-class {
    position: fixed;
    bottom: 2px;
    left: 50%;
    z-index: 2;
}
.tag-class {
    display: flex;
    justify-content: space-around;
    // width:75vw;
    max-width: 75vw;
    overflow: auto;

    .tagItem-class {
        padding: 5px;
        cursor: pointer;
    }
}
</style>
