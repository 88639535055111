<template>
  <dialogModule
    width="680px"
    title="差异修改"
    v-if="popupDialog"
    :dialogVisible="popupDialog"
    @close="closeDialog"
  >
    <span
      slot="close"
      class="el-icon-close close-icon"
      @click="closeDialog"
    ></span>
    <div slot="content" class="steps" style="padding: 10px;">
      <el-steps :active="Number(curSetp)" align-center>
        <el-step>
          <div slot="title">
            <div>APP<br />扫描二维码</div>
          </div>
        </el-step>
        <el-step>
          <div slot="title">
            <div>上传差异<br />修改视频</div>
          </div>
        </el-step>
        <el-step>
          <div slot="title">
            <div>等待后台<br />审核</div>
          </div>
        </el-step>
        <el-step>
          <div slot="title">
            <div>修改差异<br />成功</div>
          </div>
        </el-step>
      </el-steps>

      <section
        class="differ-table"
        style="margin-top: 30px; border-bottom: 1px solid #eee"
      >
        <grid-manager
          v-if="gridOption.ajaxData"
          :option="gridOption"
          ref="grid"
        >
        </grid-manager>
      </section>
      <p class="repeal-btn" @click="repealHandel">撤销修改</p>
      <p style="color: #f8475f;text-align: center;margin-top: 10px;margin-right:80px;">{{videoList.length ? '差异修改视频' : '请使用APP扫码'}}</p>
      <div style="text-align: center; margin-top: 30px" v-if="identifyBase64">
        <img
          :src="identifyBase64"
          style="
            width: 160px;
            height: 160px;
            border: 1px solid #eee;
            margin: 10px auto;
          "
        />
      </div>

      <section v-if="videoList.length" style="display:flex;margin-top:10px;">
        <div v-for="(video, index) in videoList" :key="index" style="width:200px;height:200px;margin-right:10px;border: 1px solid #eee;">
          <video :src="video" style="width:200px;height:200px;" controls="controls"></video>
        </div>
      </section>
    </div>
  </dialogModule>
</template>

<script>
import dialogModule from "@/components/dialogModule.vue";
import { queryChangeList, revocationUpdate } from '@/libs/http/modules/stock'
export default {
  components: {
    dialogModule,
  },
  props: ['fetchId'],
  data() {
    return {
      popupDialog: false,
      identifyBase64: "",
      curSetp: '0',
      videoList: [],
      gridOption: {
        gridManagerName: "change-table",
        height: "240px",
        supportAutoOrder: false,
        autoOrderConfig: {
          width: 50,
          fixed: undefined,
        },
        firstLoading: true,
        isCombSorting: false,
        supportCheckbox: false,
        checkboxConfig: {
          useRowCheck: true,
          fixed: "left",
        },
        supportAjaxPage: false,
        disableBorder: true,
        disableLine: true,
        columnData: [
          // {
          //   key: "areaName",
          //   text: "区域",
          //   width: "100px",
          // },
          {
            key: "materialNo",
            text: "货号",
            // width: "100px",
          },
          {
            key: "colorDescription",
            text: "颜色",
            // width: "80px",
          },
          {
            key: "size",
            text: "尺码",
            // width: "80px",
          },
          {
            key: "oldNumber",
            text: "原盘点数量",
            // width: "120px",
          },
          {
            key: "newNumber",
            text: "修改后盘点数量",
            // width: "150px",
          },
        ],
        dataKey: 'items',
        ajaxData: queryChangeList({id: this.fetchId}),
      },
    };
  },
  created() {
    this.popupDialog = true;
    this.fetchCahnge();
  },
  methods: {
    closeDialog() {
      this.popupDialog = false;
      this.$emit("close");
    },
    fetchCahnge(){
      queryChangeList({
        id: this.fetchId
      }).then(data=>{
        this.curSetp = data.state;
        this.identifyBase64 = data.identifyBase64;
        if(data.uploadFiles){
          this.videoList = data.uploadFiles.split(',');
          this.curSetp = '2';
        }
      }).catch(err=>{
        this.$message({type:'error',message:err,duration:2000,showClose:true});
      })
    },
    repealHandel(){
      this.$confirm('确定撤销审核？', '提示').then(()=>{
        revocationUpdate({
          ids: [this.fetchId]
        }).then(data=>{
          this.$message({type:'success',message: '操作成功',duration:2000,showClose:true});
          this.popupDialog = false;
          this.$emit("close", true);
        }).catch(err=>{
          this.$message({type:'error',message:err,duration:2000,showClose:true});

        })
      }).catch()
    }
  },
};
</script>

<style lang="scss" scoped>
.close-icon {
  color: #999;
  padding: 10px;
  margin-right: -10px;
  cursor: pointer;
}
.repeal-btn{
  float:left;
  width:80px;
  height:30px;
  background:#067CF2;
  color:#fff;
  border-radius:4px;
  line-height:30px;
  text-align:center;
  font-family: 'font-Light';
  margin-top:4px;
  cursor:pointer;
}
</style>

<style>
.steps .el-step__title {
  font-size: 14px;
  font-family: "font-Light";
  line-height: 20px;
  margin-top: 10px;
}
</style>