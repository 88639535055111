<template>
    <el-table
        ref="siezGroupTableIns"
        class="size-group-table"
        width="100%"
        max-height="700px"
        type="index"
        header-cell-class-name="size-group-table-header-cell"
        :data="filterList"
        @row-click="rowClick"
        v-bind="{ ...$attrs }"
    >
        <template v-for="(item, index) in columns">
            <el-table-column
                v-if="item.type === 'index'"
                :label="item.label"
                type="index"
                :key="item.prop + index"
                :width="50"
            >
            </el-table-column>
            <!-- 金额输入框 -->
            <el-table-column
                v-else-if="item.type === 'amountInput'"
                :key="item.prop + index"
                :prop="item.prop"
                :label="item.label"
                :fixed="item.fixed || false"
                align="center"
                :width="item.width || ''"
            >
                <template v-slot="{ row }">
                    <input
                        v-model="row[item.prop]"
                        :disabled="lodash.isFunction(item.disabledRule) && item.disabledRule(row)"
                        class="amount-input input-item"
                        :class="[
                            item.getRowBgc(row),
                            lodash.isFunction(item.disabledRule) && item.disabledRule(row) ? 'is-disabled' : ''
                        ]"
                        @input="amountInput(row, row[item.prop], item.prop)"
                    />
                </template>
            </el-table-column>

            <!-- 日期时间框 -->
            <el-table-column
                v-else-if="item.type === 'date'"
                :key="item.prop + index"
                :prop="item.prop"
                :label="item.label"
                :fixed="item.fixed || false"
                align="center"
                :width="item.width || ''"
            >
                <template v-slot="{ row }">
                    <el-date-picker
                        v-model="row[item.prop]"
                        type="date"
                        :clearable="false"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                        class="date-item input-item"
                        :class="[
                            item.getRowBgc(row),
                            lodash.isFunction(item.disabledRule) && item.disabledRule(row) ? 'is-disabled' : ''
                        ]"
                        :disabled="lodash.isFunction(item.disabledRule) && item.disabledRule(row)"
                        @change="rowChange(row, row[item.prop], item.prop)"
                    >
                    </el-date-picker>
                </template>
            </el-table-column>

            <!-- 汇总计算框 -->
            <el-table-column
                v-else-if="item.type === 'count'"
                :key="item.prop + index"
                :prop="item.prop"
                :label="item.label"
                :fixed="item.fixed || false"
                align="center"
                :width="item.width || ''"
            >
                <template v-slot="{ row }">
                    <template v-for="box in checkBoxForm">
                        <div
                            class="table-total-box"
                            :key="box.prop"
                            v-if="box.value"
                            :style="{ backgroundColor: box.colBgc || 'transparent' }"
                        >
                            {{ item.getTotal(row, box.prop) }}
                        </div>
                    </template>
                </template>
            </el-table-column>

            <!-- 尺码组 -->
            <el-table-column
                v-else-if="item.prop === 'sizeInfoList'"
                :key="item.prop"
                :prop="item.prop"
                :fixed="item.fixed || false"
                align="center"
                :width="sizeGroupWidth"
            >
                <!-- 尺码组表头 -->
                <template v-slot:header="{}">
                    <div v-if="!sizeGroup.length">尺码组</div>
                    <div v-else class="table-header-size">
                        <div
                            v-if="checkBoxForm.length"
                            class="table-header-size-l"
                            :style="{
                                width: item.boxWidth || '90px',
                                height: 'auto'
                            }"
                        >
                            <!-- 复选框组 -->
                            <template v-for="box in checkBoxForm">
                                <el-checkbox
                                    v-if="box.checkShow"
                                    :key="box.prop"
                                    v-model="box.value"
                                    :disabled="box.disabled"
                                >
                                    {{ box.label }}
                                </el-checkbox>
                            </template>
                        </div>
                        <div class="table-header-size-r">
                            <!-- 尺码组 -->
                            <div class="table-header-row" v-for="(_item, _index) in sizeGroup" :key="_index">
                                <span
                                    class="table-header-col"
                                    v-for="i in _item.valueInfoList"
                                    :key="i.valueId"
                                    :class="{
                                        'col-focus': _item.valueGroupId === valueGroupId
                                    }"
                                >
                                    {{ i.value }}
                                </span>
                            </div>
                        </div>
                    </div>
                </template>
                <!-- 尺码组body -->
                <template v-slot="{ row }">
                    <template v-for="box in checkBoxForm">
                        <div class="table-content-row" :key="box.prop" v-if="box.value">
                            <!-- 尺码组左侧label -->
                            <div
                                class="table-content-l row-label"
                                :style="{
                                    width: item.boxWidth || '90px',
                                    backgroundColor: box.colBgc || 'transparent'
                                }"
                            >
                                {{ box.label }}
                            </div>
                            <!-- 尺码组行数据 -->
                            <div class="table-content-r">
                                <!-- 新增框 -->
                                <template v-for="(_row, _index) in row.sizeInfoList">
                                    <div v-if="_row.isEmpty" :key="_index" class="table-content-col empty-box"></div>
                                    <template v-else>
                                        <input
                                            :key="_index"
                                            v-if="box.colType === 'addInput'"
                                            v-model="_row[box.prop]"
                                            class="table-content-col input-item"
                                            :class="[
                                                box.getRowBgc
                                                    ? box.getRowBgc(_row, row, filterList)
                                                    : getColBgc(_row[box.prop])
                                            ]"
                                            @input="numberInput(_row, _row[box.prop], box.prop)"
                                            @change="numberChange(row, _row, _row[box.prop], box.prop, _index)"
                                            @focus="selectInput"
                                        />
                                        <!-- 验收框 -->
                                        <input
                                            v-else-if="
                                                box.colType === 'checkInput' &&
                                                ((isEmpty && +_row[checkBoxForm[0].prop] !== 0) || !isEmpty)
                                            "
                                            :key="_index"
                                            v-model="_row[box.prop]"
                                            class="table-content-col input-item"
                                            :class="[
                                                box.getRowBgc(_row, row, filterList),
                                                +_row[checkBoxForm[0].prop] === 0 && box.checkInputDisabled
                                                    ? 'is-disabled'
                                                    : ''
                                            ]"
                                            :disabled="+_row[checkBoxForm[0].prop] === 0 && box.checkInputDisabled"
                                            @input="numberInput(_row, _row[box.prop], box.prop)"
                                            @change="checkNumberChange(row, _row, _row[box.prop], box.prop, _index)"
                                            @focus="(el) => setHistoryValue(row, _row, _row[box.prop], box.prop, el)"
                                        />
                                        <!-- 文本框 -->
                                        <span
                                            v-else-if="box.colType === 'stockSpan'"
                                            class="table-content-col"
                                            :key="_index"
                                            :style="{
                                                backgroundColor: lodash.isFunction(box.colBgc)
                                                    ? box.colBgc(_row)
                                                    : box.colBgc || 'transparent'
                                            }"
                                        >
                                            {{ isEmpty && +_row[box.prop] === 0 ? '' : _row[box.prop] }}
                                        </span>

                                        <!-- 文本框2 -->
                                        <span
                                            v-else-if="box.colType === 'span'"
                                            class="table-content-col"
                                            :key="_index"
                                            :style="{
                                                backgroundColor: lodash.isFunction(box.colBgc)
                                                    ? box.colBgc(_row)
                                                    : box.colBgc || 'transparent'
                                            }"
                                        >
                                            {{ +_row[box.prop] === 0 ? '' : _row[box.prop] }}
                                        </span>

                                        <!-- 文本框及插槽 -->
                                        <span
                                            v-else
                                            class="table-content-col"
                                            :key="_index"
                                            :style="{
                                                backgroundColor: lodash.isFunction(box.colBgc)
                                                    ? box.colBgc(_row)
                                                    : box.colBgc || 'transparent'
                                            }"
                                        >
                                            {{ isEmpty && +_row[checkBoxForm[0].prop] === 0 ? '' : _row[box.prop] }}
                                        </span>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </template>
                </template>
            </el-table-column>

            <!-- 文本内容及插槽项 -->
            <el-table-column
                v-else
                :key="item.prop + index"
                :prop="item.prop"
                :label="item.label"
                :fixed="item.fixed || false"
                align="center"
                :width="item.width || ''"
                :show-overflow-tooltip="true"
            >
                <template v-slot="{ row, $index }">
                    <!-- 其他项目默认插槽 -->
                    <slot v-bind="{ row, $index, item }">
                        <span>{{ row[item.prop] }}</span>
                    </slot>
                </template>
            </el-table-column>
        </template>

        <!-- 操作栏 -->
        <el-table-column label="操作" align="center" :width="actionColWidth" v-if="actionColShow">
            <template v-slot="{ row, $index: rIndex }">
                <template v-for="(e, eIndex) in actions.list">
                    <el-button
                        v-if="e.displayRule(row)"
                        type="text"
                        size="mini"
                        :key="e.event + eIndex"
                        @click="actionClick(e.event, row, rIndex)"
                        >{{ e.label }}</el-button
                    >
                </template>
                <!-- 操作栏预留插槽 -->
                <slot name="action-append" v-bind="{ row, rIndex }"></slot>
            </template>
        </el-table-column>
        <!-- 底部合计栏及插槽 -->
        <div slot="append">
            <slot name="footer">
                <div v-if="footerOptions.length" class="size-group-table-footer" :style="{ width: bodyWidth }">
                    <div class="footer-title">合计</div>
                    <template v-for="item in footerConfig">
                        <div :key="item.prop">
                            <span class="footer-label">{{ item.label }}：</span>
                            <span class="footer-value" v-if="item.unit === 'amount'">{{
                                `￥${lodash.isFunction(item.formula) && item.formula(newDataList)}`
                            }}</span>
                            <span class="footer-value" v-else>
                                {{ lodash.isFunction(item.formula) && item.formula(newDataList) }}
                                {{ item.unit }}
                            </span>
                        </div>
                    </template>
                </div>
            </slot>
        </div>
    </el-table>
</template>

<script>
import lodash from 'lodash'
import { footerList } from './config.js' //底部合计配置文件
import { erp2OperatingQuerySizeGroupInfoIist } from '@/libs/http/modules/erp.js'
export default {
    name: 'SizeGroupTable',
    components: {},
    props: {
        //列表数据
        dataList: {
            type: Array,
            required: true,
            default() {
                return []
            }
        },
        //updataList 存放更新的数据
        updataList: {
            type: Array,
            required: false,
            default() {
                return []
            }
        },
        //table列配置
        columns: {
            type: Array,
            required: true,
            default() {
                return []
            }
        },
        // 复选框配置
        sizeInfoLabels: {
            type: Array,
            required: true,
            default() {
                return []
            }
        },
        propSizeGroup: {
            type: Array,
            default() {
                return []
            }
        },
        //操作栏配置
        actions: {
            type: Object,
            default: () => ({})
        },
        //底部配置
        footerOptions: {
            type: Array,
            default: () => []
        },
        //是否显示右侧汇总配置对象
        // numberTotal: {
        //   type: Object,
        //   default() {
        //     return { label: "汇总", isShow: true };
        //   },
        // },
        // //是否显示右侧金额汇总
        // amountTotal: {
        //   type: Object,
        //   default() {
        //     return { label: "总金额", isShow: false, countPriceKey: "" };
        //   },
        // },
        // //金额汇总计算key
        // countPriceKey: {
        //   type: String,
        //   default: "",
        // },
        //需要更新的行
        updataIdx: {
            type: String,
            default: ''
        },
        isEmpty: {
            type: Boolean,
            default: false
        },
        //过滤字段
        filterText: {
            type: String,
            default: ''
        },
        isShowRow: {
            //控制这行是否显示
            type: Boolean,
            default: false
        }
    },
    // 定义抛出的事件名称
    emits: ['actionClick', 'numberChange', 'checkNumberChange'],
    data() {
        return {
            sizeGroup: [], //表头尺码组数据
            valueGroupId: '', //聚焦行尺码组id
            checkHistoryObj: null, //历史值
            newDataList: [], //列表数据
            checkBoxForm: [], //复选框组form对象
            bodyWidth: '',
            totalObj: null,
            updataRowIdx: '' //要更新的尺码列
            // updataIdx: '',//要更新的行
        }
    },
    computed: {
        lodash() {
            return lodash
        },
        //操作栏显隐
        actionColShow() {
            return this.actions.list && this.actions.list.length > 0
        },
        //操作栏宽度
        actionColWidth() {
            const btnWidth = 60
            return this.actions.fixedWidth || btnWidth * this.actions.list.length
        },
        //表数据尺码id集合
        valueGroupIdList() {
            return Array.from(new Set(this.dataList.map((item) => item.valueGroupId)))
        },
        //底部配置
        footerConfig() {
            return this.footerOptions.map((item) => {
                if (Object.prototype.toString.call(item) === '[object String]') {
                    return footerList.find((i) => i.prop === item)
                } else if (Object.prototype.toString.call(item) === '[object Object]') {
                    return item
                }
            })
        },
        //sizeGroup宽度
        sizeGroupWidth() {
            if (this.sizeGroup.length) {
                let length = Math.max(...this.sizeGroup.map((i) => i.valueInfoList?.length || 0))
                let _length = Math.max(...this.newDataList.map((i) => i.sizeInfoList?.length || 0))
                // 单元格宽度*个数+左侧checkBox宽度+内边距
                // 解决尺码组数据对应不上问题
                let checkWidt = this.columns.find((i) => i.prop === 'sizeInfoList')?.boxWidth || 0
                let num = 60 * (length > _length ? length : _length) + parseFloat(checkWidt) + 20
                if (num < 700) {
                    num = 700
                }
                return `${num}px`
            } else {
                return '700px'
            }
        },
        filterList() {
            if (this.filterText) {
                let filterList = this.filterText.split(' ')
                if (filterList.length > 1) {
                    return this.newDataList.filter((i) => filterList.includes(i.goodsNo))
                } else {
                    return this.newDataList.filter((i) => i.goodsNo.includes(this.filterText))
                }
            } else if (this.isShowRow) {
                let key = this.sizeInfoLabels[this.sizeInfoLabels.length - 1].prop
                return this.newDataList.filter((i) => {
                    let all = i.sizeInfoList.reduce((c, R) => c + Number(R[key] ? R[key] : 0), 0)
                    if (all !== 0) {
                        return i
                    }
                })
            } else {
                return this.newDataList
            }
        },
        //行校验
        rulesList() {
            return this.columns
                .filter((item) => item.rules)
                .map((item) => {
                    return {
                        prop: item.prop,
                        rules: item.rules
                    }
                })
        }
    },
    watch: {
        actions: {
            handler(val) {
                if (val.list) {
                    lodash.forEach(val.list, (ele) => {
                        if (!lodash.isFunction(ele.displayRule)) {
                            ele.displayRule = () => true
                        }
                    })
                }
            },
            immediate: true
        },
        dataList: {
            handler(val) {
                let list = val.map((item, index) => {
                    if (!item.soleLabel) {
                        // batchNo,upstreamNo,purchaseSn,invoicesNo,
                        let { spuInfoId, purchaseInvoiceSn, invoicesSn, colorValue, purchaseOrderNo, color, goodsNo } =
                            item
                        item.soleLabel = `${spuInfoId || ''}${purchaseInvoiceSn || ''}${invoicesSn || ''}${
                            colorValue || ''
                        }${purchaseOrderNo || ''}${color || ''}${goodsNo || ''}`
                    }
                    return item
                })
                this.newDataList = this.uniqueFunc(list, 'soleLabel')
                let obj = {}
                this.footerConfig.forEach((item) => {
                    obj[item.prop] = item.formula(this.newDataList)
                })
                this.totalObj = obj
                if (this.propSizeGroup && this.propSizeGroup.length) {
                    this.sizeGroup = this.uniqueFunc(this.propSizeGroup, 'valueGroupId')
                } else {
                    if (this.valueGroupIdList.length) {
                        this.fetchSizeInfo()
                    } else {
                        this.sizeGroup = []
                    }
                }
            },
            immediate: true
        },
        updataList: {
            handler(val) {
                if (this.updataIdx !== '') {
                    let idx = this.newDataList.findIndex((item) => (item.spuInfoId + item.colorValue) == this.updataIdx) //找到唯一值的索引
                    for (let i = 0; i < this.newDataList[idx].sizeInfoList.length; i++) {
                        val[idx].sizeInfoList.forEach((item) => {
                            if (item.barCode == this.newDataList[idx].sizeInfoList[i].barCode) {
                                this.newDataList[idx].sizeInfoList.splice(i, 1, item)
                            }
                        })
                    }
                    let obj = {}
                    this.footerConfig.forEach((item) => {
                        obj[item.prop] = item.formula(this.newDataList)
                    })
                    this.totalObj = obj
                }
            },
            immediate: false
        },
        sizeInfoLabels: {
            handler(val) {
                this.checkBoxForm = lodash.cloneDeep(this.sizeInfoLabels)
            },
            immediate: true
        },
        sizeGroup(val) {
            if (val.length) {
                let list = this.newDataList.map((item) => {
                    let valueInfoList = val.find((row) => row.valueGroupId === item.valueGroupId)?.valueInfoList || []
                    if (valueInfoList.length === item.sizeInfoList.length) {
                        return item
                    } else {
                        let _list = valueInfoList.map((size) => {
                            let arr = item.sizeInfoList.filter((i) => i.value == size.value)
                            if (arr && arr.length) {
                                return arr.find((i) => i.number > 0) || arr[0]
                            } else {
                                return {
                                    value: size.value,
                                    isEmpty: true
                                }
                            }
                        })
                        return {
                            ...item,
                            sizeInfoList: _list
                        }
                    }
                })
                this.newDataList = list
            }
        }
    },
    created() {},
    updated() {
  	    this.$nextTick(() => {
       	    this.$refs['siezGroupTableIns'].doLayout()
    	})
    },
    mounted() {
        this.$nextTick(() => {
            this.bodyWidth = this.$refs.siezGroupTableIns.layout.bodyWidth
                ? this.$refs.siezGroupTableIns.layout.bodyWidth + 'px'
                : 0
        })
    },
    methods: {
        selectInput(el) {
            el.target.select()
        },
        //查询尺码组
        fetchSizeInfo() {
            erp2OperatingQuerySizeGroupInfoIist({ valueGroupIds: this.valueGroupIdList })
                .then((data) => {
                    if (data && data.length) {
                        this.sizeGroup = data || []
                    }
                })
                .catch((err) => {
                    this.$message({
                        type: 'error',
                        message: err,
                        duration: 2000,
                        showClose: true
                    })
                })
        },
        // 行点击,设置聚焦行id
        rowClick(row, index, e) {
            this.valueGroupId = row.valueGroupId
        },
        //操作栏回调
        actionClick(event, row, rIndex) {
            this.$emit('actionClick', event, row, rIndex)
        },
        numberInput(_row, _value, _key) {
            let value = +_value.toString().replace(/[^\d]/g, '')
            this.$set(_row, _key, value)
        },
        amountInput(_row, _value, _key) {
            let amount = +_value
                .toString()
                .replace('.', '$#$')
                .replace(/\./g, '')
                .replace('$#$', '.')
                .replace(/^\./g, '')
                .replace('-', '$#$')
                .replace(/\-/g, '')
                .replace('$#$', '-')
                .replace(/[^\-\d.]/g, '')
                .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
            this.$set(_row, _key, amount)
        },
        rowChange(row, value, key) {
            this.$set(row, key, value)
        },
        /**
         * @description: 单元格变化回调
         * @param {*} row  行数据
         * @param {*} _row 行数据-->尺码组数据
         * @param {*} _value 当前变化后值
         * @param {*} _key  行数据-->尺码组数据-尺码组key
         * @return {*}
         */
        numberChange(row, _row, _value, _key) {
            if (!row.modify) {
                this.$set(row, 'modify', true) //当前行是否输入个判断条件
            }
            if (!_row.modify) {
                this.$set(_row, 'modify', true) //当前单元行是否输入个判断条件
            }
            this.$emit('numberChange', row, _row, _value, _key)
        },
        //验收输入框数据变化
        checkNumberChange(row, _row, _value, _key, rowIdx) {
            if (!row.modify) {
                this.$set(row, 'modify', true) //当前行是否输入个判断条件
            }
            if (!_row.modify) {
                this.$set(_row, 'modify', true) //当前单元行是否输入个判断条件
            }
            // updataRowIdx:'',//要更新的尺码列
            // updataIdx:'',//要更新的行
            let idx = row.spuInfoId + row.colorValue //用来获取当前表格的行索引
            this.$emit('update:updataIdx', idx + '')
            this.updataRowIdx = rowIdx
            this.$emit('checkNumberChange', row, _row, _value, this.checkHistoryObj.value, _key)
        },

        // 设置历史值
        setHistoryValue(row, _row, _value, _key, el) {
            el.target.select()
            this.checkHistoryObj = {
                soleLabel: row.soleLabel,
                barCode: _row.barCode,
                key: _key,
                value: _value
            }
        },
        //单元格恢复历史值
        restoreCheckValue() {
            if (this.checkHistoryObj) {
                this.newDataList.forEach((item) => {
                    if (item.soleLabel === this.checkHistoryObj.soleLabel) {
                        item.sizeInfoList.forEach((_item) => {
                            if (_item.barCode === this.checkHistoryObj.barCode) {
                                this.$set(_item, this.checkHistoryObj.key, this.checkHistoryObj.value)
                            }
                        })
                    }
                })
            }
        },
        // 右侧汇总计算
        getTotal(row, _key) {
            return row.sizeInfoList.reduce((c, R) => c + Number(R[_key] ? R[_key] : 0), 0)
        },
        //获取当前单元格背景色
        getColBgc(val) {
            if (+val !== 0) {
                return 'row-bg-green'
            } else {
                return ''
            }
        },
        // 去重
        uniqueFunc(arr, uniId) {
            const res = new Map()
            return arr.filter((item) => !res.has(item[uniId]) && res.set(item[uniId], 1))
        },
        //获取列表数据
        getsendData(state = false, addKey = 'number') {
            let list = []
            //数据校验
            if (this.rulesList.length) {
                for (let k = 0; k < this.rulesList.length; k++) {
                    let rule = this.rulesList[k]
                    for (let i = 0; i < this.newDataList.length; i++) {
                        if (!rule.rules(this.newDataList[i])) {
                            return false
                        }
                    }
                }
            }
            if (state) {
                for (let i = 0; i < this.newDataList.length; i++) {
                    let item = this.newDataList[i]
                    for (let j = 0; j < item.sizeInfoList.length; j++) {
                        //判断这个货品都有输入并且过滤掉等于0的尺码数据
                        // size.modify &&
                        let size = item.sizeInfoList[j]
                        if (size[addKey] > 0) {
                            let obj = {
                                spu: item.spu,
                                goodsNo: item.goodsNo,
                                spuInfoId: item.spuInfoId,
                                sku: size.sku,
                                barCode: size.barCode,
                                skuInfoId: size.skuInfoId,
                                [addKey]: size[addKey],
                                price: item.price,
                                retailPrice: item.retailPrice,
                                batchNo: item.batchNo,
                                purchasePrice: item.purchasePrice || undefined,
                                purchaseInvoicesId: item.purchaseInvoicesId || item.invoicesId || undefined,
                                id: size.id,
                                procedureFollowId: size.procedureFollowId || undefined,
                                firstDeliveryTime: item.firstDeliveryTime || undefined,
                                finalDeliveryTime: item.finalDeliveryTime || undefined,
                                calloutType: item.calloutType || undefined //店店调拨单需要
                            }
                            list.push(obj)
                        }
                    }
                }
            } else {
                list = this.newDataList
            }
            return list
        }
    }
}
</script>
<style lang="scss">
//固定底部合计
.size-group-table .el-table__append-wrapper {
    position: sticky;
    bottom: 0;
}
</style>
<style lang="scss">
.row-bg-red {
    background-color: #f8475f !important;

    .el-input__inner {
        background-color: #f8475f !important;
    }
}

.row-bg-green {
    background-color: #43d268 !important;

    .el-input__inner {
        background-color: #43d268 !important;
    }
}

.row-bg-blue {
    background-color: #067cf2 !important;

    .el-input__inner {
        background-color: #067cf2 !important;
    }
}
.row-bg-zero {
    background-color: #f9f3ed !important;
    .el-input__inner {
        background-color: #067cf2 !important;
    }
}
.row-bg-exceed {
    background-color: #ffccc7 !important;
    .el-input__inner {
        background-color: #067cf2 !important;
    }
}
// #f9f3ed
.row-bg-cream {
     background-color: #f9f3ed !important;

    .el-input__inner {
        background-color: #f9f3ed !important;
    }
}

.is-disabled {
    cursor: not-allowed;
}

.size-group-table {
    .el-table__append-wrapper {
        overflow: visible;
    }

    .input-item {
        border: none;
        background-color: #facd91;

        .el-input__inner {
            background-color: #facd91;
        }
    }

    .cell {
        padding: 0;
    }

    .el-table__row .el-table__cell {
        padding: 0;
        height: 26px !important;
    }

    .size-group-table-header-cell {
        background: #fafafa !important;
    }

    .table-header-size {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 5px 0;

        .table-header-size-l {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        .table-header-size-r {
            .table-header-row {
                display: flex;
                justify-content: flex-start;
            }

            .table-header-col {
                border: 1px solid #e6ebf5;
                background: #fff;
                width: 60px;
                height: 26px;
                line-height: 26px;
                text-align: center;
                border-left: none;

                &:first-child {
                    border-left: 1px solid #e6ebf5;
                }
            }

            .col-focus {
                background-color: #c2eafc;
            }
        }
    }

    .table-content-row {
        display: flex;
        justify-content: flex-start;
        padding: 0 10px 0 0;

        // .table-content-l {
        // }
        .row-label {
            height: 25px;
            line-height: 25px;
            padding: 0 5px;
            box-sizing: initial;
            border-top: 1px solid #e6ebf5;
        }

        .table-content-r {
            flex: 1;
            display: flex;
            justify-content: flex-start;

            .table-content-col {
                border-right: 1px solid #e6ebf5;
                border-top: 1px solid #e6ebf5;
                width: 60px;
                height: 26px;
                line-height: 26px;
                text-align: center;

                &:first-child {
                    border-left: 1px solid #e6ebf5;
                }
            }

            &:first-child > .table-content-col {
                border-top: none;
            }

            .empty-box {
                background-color: #e6ebf5;
                // cursor: not-allowed;
            }
        }
    }

    .table-total-box {
        width: 100%;
        height: 26px;
        line-height: 26px;
        text-align: center;
        border-top: 1px solid #e6ebf5;

        &:first-child {
            border-top: none;
        }
    }

    .size-group-table-footer {
        height: 34px;
        line-height: 34px;
        display: flex;
        background: #f3f4f7;
    }

    .footer-title {
        padding-left: 20px;
    }

    .footer-label {
        padding-left: 20px;
    }

    .footer-value {
        color: red;
        font-size: 16px;
        font-weight: bold;
        margin-left: 0px;
    }

    .date-item {
        width: 100%;
        height: 26px;

        .el-input__inner {
            width: 100%;
            height: 26px;
            line-height: 26px;
            border: none;
            padding: 0;
            text-align: center;
            border-radius: 0;
        }

        .el-input__prefix {
            display: none;
        }

        input::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: #fff;
        }

        input:-ms-input-placeholder {
            /* IE 10+ */
            color: #fff;
        }

        input:-moz-placeholder {
            /* Firefox 18- */
            color: #fff;
            opacity: 1;
        }

        input::-moz-placeholder {
            /* Firefox 19+ */
            color: #fff;
            opacity: 1;
        }
    }

    .amount-input {
        width: 100%;
        height: 26px;
        line-height: 26px;
        border: none;
        text-align: center;
    }
    :not(html):not(body)::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background: transparent;
    }
}
</style>
