export const differConfig = {
    editDialog: function (row) {
        // row如果没有id则需要定义id并且赋值上唯一值
        let moduleObj = {}
        moduleObj.path = require('@/views/stockModule/components/differs.vue').default //需要渲染的dialog路径
        moduleObj.ref = 'dialogVisiableRef' + row.id
        moduleObj.id = row.id //判断唯一字段。参数名必须一致
        moduleObj.modalParams = row //要传的参数
        moduleObj.isminimize = false //判断是否是最小化
        moduleObj.tagName = '编辑配货单' + ':' + row.sn //最小化标签name
        moduleObj.attrs = { receiverUnitType: '1', unitType: '2', invoiceType: '100010' } //要传的参数键值对
        return moduleObj
    },
    addDialog: function () {
        // row如果没有id则需要定义id并且赋值上唯一值
        let moduleObj = {}
        moduleObj.path = require('@/views/stockModule/components/differs.vue').default //需要渲染的dialog路径
        moduleObj.ref = 'dialogVisiableRef'
        moduleObj.id = '-1' //判断唯一字段。参数名必须一致
        moduleObj.modalParams = null //要传的参数
        moduleObj.isminimize = false //判断是否是最小化
        moduleObj.tagName = '盘带单详情' //最小化标签name
        moduleObj.attrs = { receiverUnitType: '1', unitType: '2', invoiceType: '100010' } //要传的参数键值对
        return moduleObj
    },
    detailsDialog: function (row) {
        // row如果没有id则需要定义id并且赋值上唯一值
        let moduleObj = {}
        moduleObj.path = require('@/views/stockModule/components/differs.vue').default //需要渲染的dialog路径
        moduleObj.ref = 'dialogVisiableRef' + row.id
        moduleObj.id = row.id //判断唯一字段。参数名必须一致
        moduleObj.modalParams = row //要传的参数
        moduleObj.isminimize = false //判断是否是最小化
        moduleObj.tagName = '盘点差异' //最小化标签name
        moduleObj.attrs = { drawerType: '1', isPrint: false, } //要传的参数键值对
        moduleObj.refreshList = ['fetchDetail']//存放弹窗需要刷新的方法
        return moduleObj
    },
    afterDetailsDialog: function (row) {
        // row如果没有id则需要定义id并且赋值上唯一值
        let moduleObj = {}
        moduleObj.path = require('@/views/stockModule/components/differs.vue').default //需要渲染的dialog路径
        moduleObj.ref = 'dialogVisiableRef' + row.afterMergeInvoiceId
        moduleObj.id = row.afterMergeInvoiceId //判断唯一字段。参数名必须一致
        moduleObj.modalParams = row //要传的参数
        moduleObj.isminimize = false //判断是否是最小化
        moduleObj.tagName = row.afterMergeInvoiceSn + ':' + row.warehouseName //最小化标签name
        moduleObj.attrs = { drawerType: '1', isPrint: false,isconcat:true } //要传的参数键值对
        moduleObj.refreshList = ['fetchDetail']//存放弹窗需要刷新的方法
        return moduleObj
    }
}
