<template>
    <section class="component">
        <el-form
            class="form"
            :inline="true"
            :model="options"
            label-position="right"
            @keyup.enter.native="sureHandel">
            <el-form-item v-for="item in options.searchItem" :key="item.id" :label="item.label">
                <!-- input输入类型 -->
                <el-input 
                    v-if="item.type == 'input'" 
                    v-model="item.value" 
                    :clearable="item.clearable" 
                    :placeholder="item.placeholder" 
                    :size="item.size">
                </el-input>

                <!-- select选择类型 -->
                <el-select
                    v-if="item.type == 'select'"
                    :size="item.size"
                    v-model="item.value"
                    :clearable="item.clearable"
                    :placeholder="item.placeholder">
                    <el-option
                        v-for="item in item.list"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value">
                    </el-option>
                </el-select>

                <!-- month月份选择类型 -->
                <el-date-picker
                    v-if="item.type == 'month'"
                    v-model="item.value"
                    type="month"
                    :clearable="item.clearable"
                    :size="item.size"
                    :placeholder="item.placeholder"
                    format="yyyy-MM"
                    value-format="yyyyMM">
                </el-date-picker>

                <!-- daterange时间段选择 -->
                <el-date-picker
                    v-if="item.type == 'daterange'"
                    v-model="item.value"
                    type="daterange"
                    :clearable="item.clearable"
                    :size="item.size"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    format="yyyy-MM-dd"
                    value-format="yyyyMMdd">
                </el-date-picker>

                <!-- datetimerange日期时间段选择 -->
                <el-date-picker
                    v-if="item.type == 'datetimerange'"
                    v-model="item.value"
                    type="datetimerange"
                    :clearable="item.clearable"
                    :size="item.size"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyyMMdd HH:mm:ss">
                </el-date-picker>

                <!-- radio单选 -->
                <el-radio-group v-if="item.type == 'radio'" v-model="item.value" :size="item.size">
                    <el-radio v-for="list in item.list" :key="list.name" :label="list.value">{{list.name}}</el-radio>
                </el-radio-group>

                <!-- cascader店铺筛选 -->
                <el-cascader
                    v-if="item.type == 'cascader'"
                    v-model="item.value"
                    collapse-tags
                    filterable
                    :size="item.size"
                    :options="item.list"
                    :props="item.props ? item.props : { multiple: false, emitPath: false, value: 'id', label: 'name' }"
                    :show-all-levels="item.showAllLevels ? true : false"
                    :clearable="item.clearable">
                </el-cascader>

                <!--货号选择器-->
                <erp-selector
                    style="margin-top: 4px;"
                    v-if="item.type == 'goodsNoSelector'"
                    type="goodsNo"
                    :slectShow="item.slectShow"
                    :inputShow="item.inputShow"
                    v-model="item.value"
                    :multiple="item.multiple"
                    :modelKey="item.modelKey"
                    :placeholder="item.placeholder"
                ></erp-selector>
            </el-form-item>
            <el-form-item v-if="options.sureBtnVisable || options.restBtnVisable">
                <el-button @click="sureHandel" v-if="options.sureBtnVisable" :type="options.sureBtnType" :size="options.sureBtnSize">{{options.sureBtnText}}</el-button>
                <el-button @click="resetHandel" v-if="options.restBtnVisable" :type="options.restBtnType" :size="options.restBtnSize">{{options.restBtnText}}</el-button>
            </el-form-item>
        </el-form>
    </section>
</template>

<script>
import erpSelector from '@/components/ErpSelector/index'
export default {
    name: 'searchForm',
    props: {
        options: {
            type: Object,
            default: () => {}
        }
    },
  components: {erpSelector},
    data(){
        return{
            selectList: {}
        }
    },
    methods: {
        sureHandel(){
            this.selectList = {};
            this.options.searchItem.forEach(item=>{
                for(let key in item){
                    if(key == 'value' && item[key] != '' && (item[key] || item[key] == 0)){
                        this.selectList[item.resField] = item.value;
                    }
                }
            });
            this.$emit('searchHandel', this.selectList);
            // console.log(this.selectList)
        },
        resetHandel(){
            this.options.searchItem.forEach(item=>{
                for(let key in item){
                    if(key == 'value'){
                        item[key] = '';
                    }
                }
            })
        }
    }
}
</script>

<style scoped>
.component{
    background: #fff;
    overflow: hidden;
    border-radius: 2px;
    margin: 10px;
}
.form{
    margin: 10px;
    background: #F7F7F9;
    padding: 10px 15px 5px;
    border-radius: 2px;
    font-family: 'font-Regular';
}
.form .el-form-item{
    margin-bottom: 10px;
    margin-right:20px;
}
.form .el-input__inner {
    font-size: 14px;
    font-family: 'font-Light';
}
.form .el-button{
    font-family: 'font-Regular';
    font-size: 14px;
}
.form .el-button--small, .el-button--small.is-round{
    padding: 8px 15px;
}
</style>