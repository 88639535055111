<template>
    <div class="component">
        <div class="color-size-box">
            <div class="carousel-lub">
                <el-carousel indicator-position="none" :autoplay="false" arrow="always">
                    <el-image v-if="!imageList.length" :src="defaultimg" style="width:150px;height:120px;margin-top:60px;margin-left:25px;"></el-image>
                    <el-carousel-item
                        v-else
                        class="lunbo-box-item"
                        v-for="(item,index) in imageList"
                        :key="index"
                    >
                        <el-image :src="item" class="lunbotu" :preview-src-list="imageList"></el-image>
                    </el-carousel-item>
                </el-carousel>
            </div>
          <div style="margin-top: 5px;display: flex;flex-direction: row">
            <div class="color-title">货号: <span style="color: #0B83F3;font-weight: bolder;padding-left: 20px;font-size: 30px;">{{queryInfoRow.goodsNo}}</span></div>
          </div>
          <div style="margin-top: 5px;display: flex;flex-direction: row">
            <div class="color-title">盘点总数: <span style="color: #0B83F3;font-weight: bolder;padding-left: 20px;font-size: 30px;">{{queryInfoRow.inventoryNumber}}</span></div>
          </div>
            <div class="row color-choose">
                <div class="color-title">颜色筛选:</div>
                <div class="row flex-wrap color-list" v-if="colorList">
                    <div
                        v-for="item in colorList"
                        :key="item.colorDescription"
                        :class="['cursor', 'style-one', colorDescription === item.colorDescription ? 'style-two' : '']"
                        @click="getColor(item)">
                        {{item.colorDescription || '全部颜色'}}
                    </div>
                </div>
            </div>
            <div class="size-num">
                <div class="row size-array" v-for="(item, index) in sizeList" :key="index">
                    <div class="array-head">
                        <div class="head-one">尺码：</div>
                        <div class="head-two">盘点数量:</div>
                    </div>
                    <div class="row flex-wrap size-box">
                        <div class="size-item" v-for="(sizeItem, s) in item" :key="s">
                            <div class="size-title">{{sizeItem.size}}</div>
                            <div class="size-input">
                                <el-input @focus="getDefNum(sizeItem.defaultnum)" @blur="getChangeNum(sizeItem)" :disabled="invoiceState === '7' || colorDescription === '' || queryInfoRow.updateState === '1'" style="width:40px;line-height:30px;border-radius:4px;border:1px solid #eee;" v-model="sizeItem.number" :style="sizeItem.isChange?'border:1px solid red;':'border:1px solid #eee;'"></el-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <el-button class="save-btn" :loading="loading" v-if="!queryInfoRow.differenceUpdateId" @click="saveChange">保存修改</el-button>
            <div class="save-btn" v-else @click="showCahnge">查看修改</div>
        </div>
    </div>
</template>

<script>
import {
  addDifferenceUpdate,
  queryDifferColorArea,
  queryDifferSizeNum,
} from '@/libs/http/modules/stock'
export default {
    name: 'colorSize',
    props: ['queryInfoRow', 'queryType', 'chooseAreaId', 'invoiceState'],
    data(){
        return {
            defaultimg: require('@/assets/images/differ-def.png'),
            colorList: [],
            imageList: [],
            colorDescription: '',
            areaId: '',
            sizeList: [],
            isCahnge: false,
            defNum: 0,
            cahngeList: [],
            differId: '',
            loading: false,
        }
    },
    created(){
        this.fetch();
    },
    methods: {
        fetch(){
          queryDifferColorArea({
            id: this.queryInfoRow.recordInfoId,
            goodsNo: this.queryInfoRow.goodsNo
          }).then(data=>{
            this.cahngeList = [];
            this.colorList = data.colorInfoList || [];
            this.colorList.push({colorDescription: ''});
            this.colorDescription = this.colorList[0].colorDescription;
            this.imageList = data.imageList || [];
            this.fetchSizeNum();
          }).catch(err=>{
            this.$message({type: 'error', message:err,duration:2000,showClose:true})
          })
        },
        getColor(item){
          if(this.colorDescription === item.colorDescription) return
          this.colorDescription = item.colorDescription;
          this.fetchSizeNum();
        },
        arrayHandel(list){
          let pages = [];
          list.forEach((item, index) => {
            this.$set(item, 'defaultnum', item.number);
            const page = Math.floor(index / 5);
            if (!pages[page]) {
              pages[page] = [];
            }
            pages[page].push(item);
          });
          return pages;
        },
        fetchSizeNum(){
          queryDifferSizeNum({
            id: this.queryInfoRow.recordInfoId,
            goodsNo: this.queryInfoRow.goodsNo,
            queryType: this.queryType || '0',
            colorValue: this.colorDescription
          }).then(data=>{
              if(data){
                data.forEach(item=>{
                  this.$set(item, 'isChange', false);
                  this.cahngeList.forEach(list=>{
                    if(item.size === list.size && item.number !== list.newNumber && this.colorDescription === list.colorDescription){
                      item.number = list.newNumber;
                      item.isChange = true;
                    }
                  });
                });
                this.sizeList = this.arrayHandel(data);
              }else{
                this.sizeList = [];
              }
            }).catch(err=>{
                this.$message({type: 'error', message:err,duration:2000,showClose:true})
            })
        },
        getDefNum(num){
            this.defNum = num;
        },
        getChangeNum(sizeItem){
          if(sizeItem.number % 1 !== 0 || sizeItem.number.includes('.')){
            sizeItem.number = this.defNum
            return this.$message({type: 'error', message: '请填写有效数字',duration:2000,showClose:true})
          }
            if(sizeItem.number !== this.defNum){
                this.$set(sizeItem, 'isChange', true);
                if(!this.cahngeList.length){
                    this.cahngeList.push({
                      areaId: this.areaId,
                      size: sizeItem.size,
                      oldNumber: this.defNum,
                      newNumber: sizeItem.number,
                      spuInfoId: sizeItem.spuInfoId,
                      colorDescription: this.colorDescription,
                      differenceInfoId: sizeItem.differenceInfoId
                    });
                }else{
                    let res = this.cahngeList.some(item=>{
                        if(item.size === sizeItem.size && item.colorDescription === this.colorDescription){
                            item.newNumber = sizeItem.number;
                        }
                        return item.size === sizeItem.size && item.colorDescription === this.colorDescription;
                    });
                    if(!res){
                        this.cahngeList.push({
                          areaId: this.areaId,
                          size: sizeItem.size,
                          oldNumber: this.defNum,
                          newNumber: sizeItem.number,
                          spuInfoId: sizeItem.spuInfoId,
                          colorDescription: this.colorDescription,
                          differenceInfoId: sizeItem.differenceInfoId
                        });
                    }
                }
            }
        },
        saveChange(){
          let index = this.cahngeList.findIndex(item=>{
              return item.oldNumber === item.newNumber;
          });
          if(index !== -1){
              this.cahngeList.splice(index, 1);
          }
          if(!this.cahngeList.length){
              return this.$message({type: 'error', message: '您还没有修改数据',duration:2000,showClose:true})
          }
          this.loading = true;
          addDifferenceUpdate({
            id: this.queryInfoRow.recordInfoId,
            spuInfoId: this.cahngeList[0].spuInfoId,
            differenceInfoList: this.cahngeList.map(item=>{ return {
              areaId: item.areaId,
              oldNumber: item.oldNumber,
              newNumber: item.newNumber,
              differenceInfoId: item.differenceInfoId
            }})
          }).then(res=>{
            this.$message({type: 'success', message: '保存成功',duration:2000,showClose:true})
            this.differId = res.id;
            this.$emit('changeValue', this.differId);
            this.loading = false;
          }).catch(error=>{
            this.loading = false;
            this.$message({type: 'error', message: error,duration:2000,showClose:true})
          })
        },
        showCahnge(){
          this.$emit('changeValue');
        }
    }
}
</script>

<style lang="scss" scoped>
.color-size-box{
    height: calc(100vh - 80px) !important;
    background:#fff;
    width:380px;
    padding:10px;
    box-sizing:border-box;
    margin-top:10px;
    margin-right:20px;
    border-radius:6px;
    position: relative;
    font-family: 'font-Regular';
    .carousel-lub{
        background:#F9F9F9;
        padding:10px 0;
        border-radius:6px;
        margin-bottom: 10px;
        .lunbotu{
            width:200px;
            height:240px;
        }
    }
    .color-choose{
        margin-top:10px;
        margin-bottom:20px;
        .color-title{
            margin-top:5px;
            width:80px;
        }
        .color-list{
            font-size:14px;
            flex: 1;
            .style-one{
                background:#F7F7F9;
                padding:2px 14px;
                border-radius:4px;
                margin:5px;
                border:1px solid #F7F7F9;
                box-sizing: border-box;
            }
            .style-two{
                background: #EFF7FF;
                border: 1px solid #067CF2;
            }
        }
    }
    .size-num{
        margin-top:20px;
        .size-array{
            width:360px;
            margin-bottom:20px;
            border-left:1px solid #eee;
            border-radius:4px;
            .array-head{
                width:78px;
                box-sizing:border-box;
                .head-one{
                    background:#F5F7FA;
                    line-height:40px;
                    padding-left:8px;
                    border-top:1px solid #eee;
                }
                .head-two{
                    line-height:40px;
                    padding-left:8px;
                    border-bottom:1px solid #eee;
                }
            }
        }
        .size-box{
            width:280px;
            .size-item{
                width:56px;
                text-align:center;
                box-sizing:border-box;
                &:last-child{
                    border-right: 1px solid #eee;
                }
                .size-title{
                    background:#F5F7FA;
                    line-height:40px;
                    border-top:1px solid #eee;
                }
                .size-input{
                    line-height:40px;
                    border-bottom:1px solid #eee;
                }
            }
        }
    }
    .save-btn{
        position:absolute;
        bottom:40px;
        background:#067CF2;
        color:#fff;
        width:130px;
        line-height:40px;
        border-radius:4px;
        text-align:center;
        left:115px;
        cursor:pointer;
        font-family: 'font-Regular';
        font-size: 16px;
        border: 1px solid #067CF2;
    }
}
</style>

<style>
.el-carousel__container{
    height: 240px;
    width: 200px;
    margin: auto;
}
.el-carousel__arrow--left{
    left: -80px;
}
.el-carousel__arrow--right{
    right: -80px;
}
.el-carousel__arrow{
    border-radius: 4px;
    height: 70px;
    width: 26px;
}
.size-input .el-input__inner{
    width: 38px;
    background: #fff;
    border: none;
    text-align: center;
    height: 30px;
    line-height: 30px;
    padding: 0;
}
.color-size-box .el-button{
    padding: 0;
}
</style>
