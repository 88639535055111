export const differDetail = {
    editDialog: function (row) {
        // row如果没有id则需要定义id并且赋值上唯一值
        let moduleObj = {}
        moduleObj.path = require('@/views/stockModule/components/differDetail.vue').default //需要渲染的dialog路径
        moduleObj.ref = 'dialogVisiableRef' + row.id
        moduleObj.id = row.id //判断唯一字段。参数名必须一致
        moduleObj.modalParams = row //要传的参数
        moduleObj.isminimize = false //判断是否是最小化
        moduleObj.tagName = '编辑配货单' + ':' + row.sn //最小化标签name
        moduleObj.attrs = { receiverUnitType: '1', unitType: '2', invoiceType: '100010' } //要传的参数键值对
        return moduleObj
    },
    addDialog: function () {
        // row如果没有id则需要定义id并且赋值上唯一值
        let moduleObj = {}
        moduleObj.path = require('@/views/stockModule/components/differDetail.vue').default //需要渲染的dialog路径
        moduleObj.ref = 'dialogVisiableRef'
        moduleObj.id = '-1' //判断唯一字段。参数名必须一致
        moduleObj.modalParams = null //要传的参数
        moduleObj.isminimize = false //判断是否是最小化
        moduleObj.tagName = '盘带单详情' //最小化标签name
        moduleObj.attrs = { receiverUnitType: '1', unitType: '2', invoiceType: '100010' } //要传的参数键值对
        return moduleObj
    },
    detailsDialog: function (row) {
        // row如果没有id则需要定义id并且赋值上唯一值
        let moduleObj = {}
        moduleObj.path = require('@/views/stockModule/components/differDetail.vue').default //需要渲染的dialog路径
        moduleObj.ref = 'dialogVisiableRef' + row.id
        moduleObj.id = row.id //判断唯一字段。参数名必须一致
        moduleObj.modalParams = row //要传的参数
        moduleObj.isminimize = false //判断是否是最小化
        moduleObj.tagName = '盘点差异' //最小化标签name
        moduleObj.attrs = { drawerType: '1', isPrint: false, } //要传的参数键值对
        moduleObj.refreshList = ['fetchDetail']//存放弹窗需要刷新的方法
        return moduleObj
    },
    afterDetailsDialog: function (row) {
        // row如果没有id则需要定义id并且赋值上唯一值
        let moduleObj = {}
        moduleObj.path = require('@/views/stockModule/components/differDetail.vue').default //需要渲染的dialog路径
        moduleObj.ref = 'dialogVisiableRef' + row.afterMergeInvoiceId
        moduleObj.id = row.afterMergeInvoiceId //判断唯一字段。参数名必须一致
        moduleObj.modalParams = row //要传的参数
        moduleObj.isminimize = false //判断是否是最小化
        moduleObj.tagName = row.afterMergeInvoiceSn + ':' + row.warehouseName //最小化标签name
        moduleObj.attrs = { drawerType: '1', isPrint: false,isconcat:true } //要传的参数键值对
        moduleObj.refreshList = ['fetchDetail']//存放弹窗需要刷新的方法
        return moduleObj
    }
}




export const columns = [
    {
        prop: 'goodsNo',
        label: '货号'
    },
    {
        prop: 'goodsName',
        label: '货品名称'
    },
    {
        prop: 'typeCategory1',
        label: '款式分类'
    },
    {
        prop: 'timeCategory1',
        label: '时间分类'
    },
    {
        prop: 'color',
        label: '颜色'
    },
    {
        prop: 'retailPrice',
        label: '单价'
    },
    {
        prop: 'sizeInfoList',
        width: '800px',
        boxWidth: '70px' //复选框宽度
    },
    {
        prop: 'differenceNumber',
        label: '差异数量'
    },
    {
        prop: 'compensatePrice',
        label: '赔付金额'
    }
]

export const sizeInfoLabel = [
    {
        prop: 'inventoryNumber', //复选框key//valueInfoList当前行对应key
        label: '盘点前', //复选框名称及行数据对应名称
        checkShow: false, //是否出现复选框
        value: true, //绑定值
        disabled: true //禁用
    },
    {
        prop: 'differenceNumber', //复选框key//valueInfoList当前行对应key
        label: '调整数量', //复选框名称及行数据对应名称
        checkShow: false, //是否出现复选框
        value: true, //绑定值
        disabled: true, //禁用
        colType: 'span', //当前行是否为输入框
        colBgc(_row) {
            if (_row.differenceNumber > 0) {
                return '#43D268'
            }
            if(_row.differenceNumber < 0){
                return '#f4b4bb'
            }
        }
    }
]


export const footers = [
    {
        prop: 'differenceNumber',
        label: '本页总差异数',
        unit: '',
        formula(data) {
            let num = 0
            num = data.reduce((c, R) => c + Number(R.differenceNumber || 0), 0)
            return num
        }
    },
    {
        prop: 'compensatePrice',
        label: '本页总赔付金额',
        unit: '',
        formula(data) {
            let num = 0
            num = data.reduce((c, R) => c + Number(R.compensatePrice || 0), 0)
            return num
        }
    },
]