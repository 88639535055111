//新增配置
export const addColumns = [
    {
        prop: 'goodsName',
        label: '货品名称'
    },
    {
        prop: 'goodsNo',
        label: '货号'
    },
    {
        prop: 'color',
        label: '颜色'
    },
    {
        prop: 'total',
        label: '总计',
        type: 'count',
        getTotal(row, _key) {
            return row.sizeInfoList.reduce((c, R) => c + Number(R[_key] ? R[_key] : 0), 0)
        }
    },
    {
        prop: 'sizeInfoList',
        width: '800px',
        boxWidth: '90px' //复选框宽度
    },
   
]
export const addSizeInfoLabel = [
    
    {
        prop: 'rediskNum', //复选框key//valueInfoList当前行对应key
        label: '重盘数', //复选框名称及行数据对应名称
        checkShow: false, //是否出现复选框
        value: true, //绑定值
        disabled: true, //禁用
        colType: 'checkInput', //当前行是否为输入框
        checkInputDisabled: false, //是否禁用验收输入框
        getRowBgc(_row, row, list) {
            // let total = 0
            //inventoryNumber-原盘数 rediskNum-重盘数
            if (_row?.inventoryNumber) {
                 if(+_row.inventoryNumber == +_row.rediskNum && +_row.rediskNum != 0) return 'row-bg-cream'
                 if(+_row.inventoryNumber > +_row.rediskNum && +_row.rediskNum != 0) return 'row-bg-red'
                 if(+_row.inventoryNumber < +_row.rediskNum && +_row.rediskNum != 0) return 'row-bg-green'
            }
            
            // let checkNumberTotal = 0
            // list.forEach((item) => {
            //     let shipmentNumberAll = item.sizeInfoList.reduce((c, R) => c + Number(R.number || 0), 0)
            //     total += shipmentNumberAll
            //     // ------
            //     let checkNumberAll = item.sizeInfoList.reduce((c, R) => c + Number(R.checkNum || 0), 0)
            //     checkNumberTotal += checkNumberAll
            // })
            // // ------------
            // let shipmentNumberAll = row.sizeInfoList.reduce((c, R) => c + Number(R.number || 0), 0)
            // let checkNumberAll = row.sizeInfoList.reduce((c, R) => c + Number(R.checkNum || 0), 0)
            // //-------------
            // if (total === checkNumberTotal) {
            //     return 'row-bg-red'
            // } else if (shipmentNumberAll === checkNumberAll) {
            //     return 'row-bg-blue'
            // } else if (+_row.checkNum === +_row.number) {
            //     return 'row-bg-green'
            // } else {
            //     return ''
            // }
        }
    },
   
    {
        prop: 'inventoryNumber', //复选框key//valueInfoList当前行对应key
        label: '原盘数', //复选框名称及行数据对应名称
        checkShow: false, //是否出现复选框
        value: true, //绑定值
        disabled: true //禁用
    }
]
export const addActions = {
    fixedWidth: 120,
    list: [
      {
        label: "删除",
        event: "del",
        displayRule: (row) => true,
      },
    ],
  };
  