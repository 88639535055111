export const footerList = [
  {
    prop: "totalAmount",
    label: "总款数",
    unit: "个",
    formula(data) {
      return new Set(data.map((i) => i.goodsNo)).size || 0;
    },
  },
  {
    prop: "getNum",
    label: "总件数",
    unit: "件",
    formula(data) {
      let num = 0;
      data.forEach((item) => {
        let all = item.sizeInfoList.reduce(
          (c, R) => c + Number(R.number || 0),
          0
        );
        num += all;
      });
      return num;
    },
  },
  {
    prop: "rkbdGetNum", //提货-入库补单//提货-采购计划提货
    label: "总件数",
    unit: "件",
    formula(data) {
      let num = 0;
      data.forEach((item) => {
        let all = item.sizeInfoList.reduce(
          (c, R) => c + Number(R.takeGoodsNumber || 0),
          0
        );
        num += all;
      });
      return num;
    },
  },

  {
    prop: "rkbdNumTotal", //提货-入库补单//提货-采购计划提货
    label: "总价",
    unit: "amount",
    formula(data) {
      let num = 0;
      data.forEach((item) => {
        let all = item.sizeInfoList.reduce(
          (c, R) => c + Number(R.takeGoodsNumber || 0),
          0
        );
        num += item.purchasePrice * all;
      });
      return num;
    },
  },
  {
    prop: 'dbGetNum',
    label: '调拨总件数',
    unit: '件',
    formula(data) {
        let num = 0
        data.forEach((item) => {
            let phAll = item.sizeInfoList.reduce((c, R) => c + Number(R.totalNum || 0), 0)
            num += phAll
        })
        return num
    }
},
  {
    prop: "tcGetNum",
    label: "退仓总件数",
    unit: "件",
    formula(data) {
      let num = 0;
      data.forEach((item) => {
        let all = item.sizeInfoList.reduce(
          (c, R) => c + Number(R.num || 0),
          0
        );
        num += all;
      });
      return num;
    },
  },
  {
    prop: "phGetNum",
    label: "铺货总件数",
    unit: "件",
    formula(data) {
      let num = 0;
      data.forEach((item) => {
        let phAll = item.sizeInfoList.reduce(
          (c, R) => c + Number(R.number || 0),
          0
        );
        num += phAll;
      });
      return num;
    },
  },
  {
    prop: "cgDistriPriceTotal", //采购单的采购总价
    label: "采购总价",
    unit: "amount",
    formula(data) {
      let num = 0;
      data.forEach((item) => {
        let all = item.sizeInfoList.reduce(
          (c, R) => c + Number(R.purchaseNumber || 0),
          0
        );
        num += item.purchasePrice * all;
      });
      return num.toFixed(2);
    },
  },
  {
    prop: "thDistriPriceTotal", //用于提货单--采购总价
    label: "采购总价",
    unit: "amount",
    formula(data) {
      let num = 0;
      data.forEach((item) => {
        let all = item.sizeInfoList.reduce(
          (c, R) => c + Number(R.takeGoodsNumber || 0),
          0
        );
        num += item.purchasePrice * all;
      });
      return num.toFixed(2);
    },
  },
  {
    prop: "tgDistriPriceTotal", //用于退供单---采购总价
    label: "采购总价",
    unit: "amount",
    formula(data) {
      let num = 0;
      data.forEach((item) => {
        let all = item.sizeInfoList.reduce(
          (c, R) => c + Number(R.shipmentNumber || 0),
          0
        );
        num += item.purchasePrice * all;
      });
      return num.toFixed(2);
    },
  },
  {
    prop: "tagDistriPriceTotal", //提货-吊牌总价
    label: "吊牌总价",
    unit: "amount",
    formula(data) {
      let num = 0;
      data.forEach((item) => {
        let all = item.sizeInfoList.reduce(
          (c, R) => c + Number(R.takeGoodsNumber || 0),
          0
        );
        num += item.retailPrice * all;
      });
      return num;
    },
  },
  {
    prop: "dbDistriPriceTotal",
    label: "调拨总价",
    unit: "amount",
    formula(data) {
      let num = 0;
      data.forEach((item) => {
        let all = item.sizeInfoList.reduce(
          (c, R) => c + Number(R.number || 0),
          0
        );
        num += item.price * all;
      });
      return num.toFixed(2);
    },
  },
  {
    prop: "tcDistriPriceTotal",
    label: "退仓总价",
    unit: "amount",
    formula(data) {
      let num = 0;
      data.forEach((item) => {
        let all = item.sizeInfoList.reduce(
          (c, R) => c + Number(R.number || 0),
          0
        );
        num += item.price * all;
      });
      return num.toFixed(2);
    },
  },
  {
    prop: "phDistriPriceTotal",
    label: "铺货总价",
    unit: "amount",
    formula(data) {
      let num = 0;
      data.forEach((item) => {
        let phAll = item.sizeInfoList.reduce(
          (c, R) => c + Number(R.number || 0),
          0
        );
        num += item.price * phAll;
      });
      return num.toFixed(2);
    },
  },
  {
    prop: "sellPriceTotal",
    label: "零售总价",
    unit: "amount",
    formula(data) {
      let num = 0;
      data.forEach((item) => {
        let phAll = item.sizeInfoList.reduce(
          (c, R) => c + Number(R.number || 0),
          0
        );
        num += item.retailPrice * phAll;
      });
      return num.toFixed(2);
    },
  },
  {
    prop: "checkGetNum", //验收
    label: "总件数",
    unit: "件",
    formula(data) {
      let num = 0;
      data.forEach((item) => {
        let all = item.sizeInfoList.reduce(
          (c, R) => c + Number(R.number || 0),
          0
        );
        num += all;
      });
      return num;
    },
  },

  {
    prop: "ReceivedTotal",
    label: "已收",
    unit: "件",
    formula(data) {
      let num = 0;
      data.forEach((item) => {
        let all = item.sizeInfoList.reduce(
          (c, R) => c + Number(R.checkNum || 0),
          0
        );
        num += all;
      });
      return num;
    },
  },
  {
    prop: "noReceivedTotal",
    label: "未收",
    unit: "件",
    formula(data) {
      let num = 0;
      data.forEach((item) => {
        let all = item.sizeInfoList.reduce(
          (c, R) => c + Number(R.noCheckNum || 0),
          0
        );
        num += all;
      });
      return num;
    },
  },
  {
    prop: "chuhdNumTotal",
    label: "出货差异总数",
    unit: "件",
    formula(data) {
      let num = 0;
      data.forEach((item) => {
        let chAll = item.sizeInfoList.reduce(
          (c, R) => c + Number(R.outNumDifference || 0),
          0
        );
        num += chAll;
      });
      return num;
    },
  },


  // 店铺调出单详情尺码组底部

  {
    prop: "totalDBAmount",
    label: "总款数",
    unit: "个",
    formula(data) {
      return new Set(data.map((i) => i.goodsNo)).size || 0;
    },
  },
  {
    prop: 'DBGetNum',
    label: '调拨总件数',
    unit: '件',
    formula(data) {
        let num = 0
        data.forEach((item) => {
            let phAll = item.sizeInfoList.reduce((c, R) => c + Number(R.totalNum || 0), 0)
            num += phAll
        })
        return num
    }
},


  {
    prop: 'rukudNumTotal',
    label: '入库差异总数',
    unit: '件',
    formula(data) {
        let num = 0
        data.forEach((item) => {
            let rkAll = item.sizeInfoList.reduce((c, R) => c + Number(R.inNumDifference || 0), 0)
            num += rkAll
        })
        return num
    }
},
{
  prop: "chuhdbNumTotal",
  label: "出货差异总数",
  unit: "件",
  formula(data) {
    let num = 0;
    data.forEach((item) => {
      let chAll = item.sizeInfoList.reduce(
        (c, R) => c + Number(R.outNumDifference || 0),
        0
      );
      num += chAll;
    });
    return num;
  },
},
  {
    prop: "rukudbNumTotal",
    label: "入库差异总数",
    unit: "件",
    formula(data) {
      let num = 0;
      data.forEach((item) => {
        let rkAll = item.sizeInfoList.reduce(
          (c, R) => c + Number(R.storageDifferenceNumber || 0),
          0
        );
        num += rkAll;
      });
      return num;
    },
  },
  {
    prop: "cukudNumTotal",
    label: "出库差异总数",
    unit: "件",
    formula(data) {
      let num = 0;
      data.forEach((item) => {
        let ckAll = item.sizeInfoList.reduce(
          (c, R) => c + Number(R.shipmentDifferenceNumber || 0),
          0
        );
        num += ckAll;
      });
      return num;
    },
  },
  {
    prop: "adjustTotal",
    label: "调整总件数",
    unit: "件",
    formula(data) {
      let num = 0;
      data.forEach((item) => {
        let all = item.sizeInfoList.reduce(
          (c, R) => c + Number(R.number || 0),
          0
        );
        num += all;
      });
      return num;
    },
  },
  {
    prop: "tackNum",
    label: "已提数",
    unit: "件",
    formula(data) {
      let num = 0;
      data.forEach((item) => {
        let all = item.sizeInfoList.reduce(
          (c, R) => c + Number(R.takeGoodsNumber || 0),
          0
        );
        num += all;
      });
      return num;
    },
  },
  {
    prop: "notackNum",
    label: "未提数",
    unit: "件",
    formula(data) {
      let num = 0;
      data.forEach((item) => {
        let all = item.sizeInfoList.reduce(
          (c, R) => c + Number(R.notTakeGoodsNumber || 0),
          0
        );
        num += all;
      });
      return num;
    },
  },
  {
    prop: "intoNum",
    label: "入库数",
    unit: "件",
    formula(data) {
      let num = 0;
      data.forEach((item) => {
        let all = item.sizeInfoList.reduce(
          (c, R) => c + Number(R.intoStockNumber || 0),
          0
        );
        num += all;
      });
      return num;
    },
  },

  {
    prop: "returnNum",
    label: "退供数",
    unit: "件",
    formula(data) {
      let num = 0;
      data.forEach((item) => {
        let all = item.sizeInfoList.reduce(
          (c, R) => c + Number(R.returnGoodsNumber || 0),
          0
        );
        num += all;
      });
      return num;
    },
  },
  {
    prop: "returnPrice",
    label: "退供金额",
    unit: "amount",
    formula(data) {
      let num = 0;
      data.forEach((item) => {
        let all = item.sizeInfoList.reduce(
          (c, R) => c + Number(R.returnGoodsNumber || 0),
          0
        );
        num += all * item.purchasePrice;
      });
      return num.toFixed(2);
    },
  },
  {
    prop: "pickReceivedTotal", //拣货
    label: "已拣",
    unit: "件",
    formula(data) {
      let num = 0;
      data.forEach((item) => {
        let all = item.sizeInfoList.reduce(
          (c, R) => c + Number(R.actualPickNumber || 0),
          0
        );
        num += all;
      });
      return num;
    },
  },
  {
    prop: "pickNoReceivedTotal", //拣货
    label:"未拣",
    unit: "件",
    formula(data) {
      let num = 0;
      data.forEach((item) => {
        let all = item.sizeInfoList.reduce(
          (c, R) => c + Number(R.notPickNumber || 0),
          0
        );
        num += all;
      });
      return num;
    },
  },
];
