<template>
  <section>
    <Zdialog
      width="90%"
      ref="zdialog"
      :title="'【差异明细】' + modalParams.seq"
      zDialogRef="z-dialog1"
      :show.sync="drawerVisible"
      :isConfirmClose="false"
      :isFooter="false"
      :modal="false"
      :hasBodyClass="false"
      :hasMinBtn="false"
      @close="close"
      @minimizeVal="minimizeVal"
      class="stockZdialog">
      <section slot="body" class="content-box"
       v-loading="loading"
       element-loading-text="请稍后，全力加载中..."
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0)">
        <el-descriptions :column="4" border>
          <el-descriptions-item>
            <template slot="label">
              盘点单名称
            </template>
            {{dataInfo.name}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              盘点单类型
            </template>
            {{dataInfo.typeDesc}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              店铺名称
            </template>
            {{dataInfo.forDeptName}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              状态
            </template>
            {{dataInfo.stateDesc}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              创建时间
            </template>
            {{dataInfo.createTime}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              完盘时间
            </template>
            {{dataInfo.finishTime}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              审核时间
            </template>
            {{dataInfo.reviewTime}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              创建人
            </template>
            {{dataInfo.createUser}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              盘点总数
            </template>
            {{dataInfo.inventoryNumber}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              库存总数
            </template>
            {{dataInfo.stockNumber}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              差异总数
            </template>
            {{dataInfo.differenceNumber}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              赔付总金额
            </template>
            {{dataInfo.differencePrice}}
          </el-descriptions-item>
        </el-descriptions>
        <section class="row align-center search-section">
          <div class="row align-center">
            <span>货号</span>
            <erp-selector
                placeholder="请选择货号"
                size="mini"
                modelKey="goodsNo"
                type="goodsNo"
                ref="goodsNo"
                v-model="queryParams.goodsNo"
                :multiple="true"
            ></erp-selector>
          </div>
          <el-button class="search-btn" type="primary" size="small" :loading="loading" @click="fetchHadeel">查 询</el-button>
        </section>
        <SizeGroupTable
            border
            ref="sizeGroupTableIns"
            height="650px"
            :dataList="dataList"
            :footerOptions="footers"
            :sizeInfoLabels="sizeInfoLabel"
            :columns="columns"
            :isEmpty="true">
        </SizeGroupTable>
        <el-pagination
            layout="total, sizes, prev, pager, next"
            :current-page="page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            :total="totalItem"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
      </section>
    </Zdialog>
  </section>
</template>

<script>
import Zdialog from '@/components/ZDialog/index.vue'
import erpSelector from '@/components/ErpSelector/index'
import SizeGroupTable from '@/components/SizeGroupTable/index.vue'
import { columns, sizeInfoLabel, footers } from './differDetailConfig'
import {queryDifferDetailInfo, queryDifferDetailList} from "@/libs/http/modules/stock";
export default {
  props: {
    modalParams: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    Zdialog,
    erpSelector,
    SizeGroupTable
  },
  data() {
    return {
      dataInfo: {},
      loading: false,
      drawerVisible: false,
      queryParams: {
        goodsNo: ''
      },
      columns,
      footers,
      sizeInfoLabel,
      dataList: [],
      page: 1,
      pageSize: 10,
      totalItem: 0
    };
  },
  created() {
    this.drawerVisible = true;
    this.fetchInfo()
    this.queryData()
  },
  methods: {
    close() {
      this.$emit('close', ['close', this.modalParams])
    },
    minimizeVal(e) {
      //是否是最小化，是的话就传给父组件
      if (e) {
        this.$emit('close', ['', this.modalParams])
      }
    },
    isMinimize() {
      this.$refs['zdialog'].minimize()
    },
    fetchInfo(){
      queryDifferDetailInfo({
        recordInfoId: this.modalParams.id
      }).then(res=>{
        this.dataInfo = res
      }).catch(error=>{
        this.$message({type: 'error', message: error, duration: 2000, showClose: true})
      })
    },
    fetchHadeel(){
      this.page = 1
      this.queryData()
    },
    queryData(){
      this.loading = true
      let query = {
        page: this.page,
        pageSize: this.pageSize,
        recordInfoId: this.modalParams.id
      }
      if(this.queryParams.goodsNo && this.queryParams.goodsNo.length){
        query['goodsNo'] = this.queryParams.goodsNo.join(' ')
      }
      queryDifferDetailList(query).then(res=>{
        this.dataList = res.items
        this.totalItem = Number(res.totalItem)
        this.loading = false
      }).catch(error=>{
        this.loading = false
        this.$message({type: 'error', message: error, duration: 2000, showClose: true})
      })
    },
    handleSizeChange(e){
      this.pageSize = e
      this.queryData()
    },
    handleCurrentChange(e){
      this.page = e
      this.queryData()
    },
  },
};
</script>

<style lang="scss" scoped>
.content-box{
  margin: 20px;
}
.search-section{
  margin: 20px 0;
  span{
    width: 40px;
  }
  .search-btn{
    margin-left: 20px;
  }
}
</style>