<template>
  <el-dialog
    width="1340px"
    class="singleRedisc-class"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :before-close="handleClose">
    <span slot="title" class="singleRedisc-title">
      <h3>单款重盘</h3>
      <span v-if="modalParams">盘点单：【 {{ modalParams.typeDesc }} 】{{ modalParams.inventoryName }} </span>
    </span>
    <section>
      <div class="search-row">
        <section class="row align-center">
          <div class="search-card">
            <div class="search">
              <i
                  class="iconfont icon-saoma"
                  style="font-size: 24px"
                  :style="{ color: isInput ? '#C56209' : '#067CF2' }"
              ></i>
              <div class="search-line" :style="{ background: isInput ? '#C56209' : '#067CF2' }"></div>
              <input
                  type="text"
                  ref="searchCode"
                  placeholder="请扫码或输入条码"
                  @focus="isInput = true"
                  @blur="isInput = false"
                  v-focusInput="1"
                  @keyup.enter="searchCode($event)"
                  v-model="barCode"
              />
              <div class="search-btn" @click="searchCode()">确定</div>
            </div>
          </div>
          <el-switch
              size="small"
              v-model="scanFlag"
              inactive-color="#44DB5E"
              active-color="#ff0000"
              active-text="负扫"
              inactive-text="正扫"
              @change='control'
              style="margin: 0 20px"
          ></el-switch>
          <el-button type="primary" size="small" @click="selectDiff">选择盘点差异货品</el-button>
        </section>
        <section class="row align-center">
          <el-input placeholder="请输入货号/简码/条码手动添加" style="width:230px;border-radius:0;" clearable
                    class="manuallyAdd-class" size="small" v-model="manuallyAdd" @keyup.native.enter="addDirectly">
          </el-input>
          <el-button type="primary" size="small" style="border-radius:0;" @click="addDirectly">直接添加</el-button>
          <div style="margin-left:20px">总数量：{{ scanSum }}</div>
        </section>
      </div>
      <SizeGroupTable
          border
          ref="sizeGroupTableIns"
          v-loading='loading'
          :dataList="newDataList"
          :actions="addActions"
          :columns="addColumns"
          :sizeInfoLabels="addSizeInfoLabel"
          @actionClick="actionClick"
          @checkNumberChange="checkNumberChange"
      >
        <!-- <template v-slot="{ row, item }">
            <el-popover v-if="item.prop === 'goodsNo'" placement="right-start" trigger="click">
                <el-image
                    v-if="row.imageList && row.imageList.length"
                    style="width: 400px; height: 400px"
                    :src="row.imageList[0]"
                    :preview-src-list="row.imageList"
                >
                </el-image>
                <span slot="reference" class="text-link">{{ row.goodsNo }} </span>
            </el-popover>
        </template> -->
      </SizeGroupTable>
    </section>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitDetail" size="small">{{ '确定提交' }} </el-button>
    </span>
    <inventoryDiffLis
      v-if="inventoryDiffLisDialog"
      :rowData='modalParams'
      ref="inventoryDiffLisRef"
      @close="inventoryDiffLisDialog = false"
      @selectDiffData="selectDiffData">
    </inventoryDiffLis>
  </el-dialog>
</template>

<script>
import SizeGroupTable from '@/components/SizeGroupTable/index.vue'
import inventoryDiffLis from '../components/inventoryDiffList.vue'
import {queryInventoryDifferenceGoodsInfo, addInventoryDifferenceAgainInventoryInfo} from '@/libs/http/modules/stock'
import {addColumns, addSizeInfoLabel, addActions} from '../components/singleRedisc.js'
export default {
  components: {
    SizeGroupTable,
    inventoryDiffLis
  },
  props: {
    modalParams: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      inventoryDiffLisDialog: false,
      dialogVisible: false,
      newDataList: [],
      addColumns,
      addSizeInfoLabel,
      addActions,
      isInput: false,
      barCode: '',//条码
      scanFlag: false,
      manuallyAdd: '',//手动添加
      rowData: null,
      loading: false,
      scanSum: 0,//本次扫码总数
    }
  },
  created() {
    this.$nextTick(() => {
      this.$refs.searchCode.focus()
    })
    this.dialogVisible = true
    this.rowData = this.modalParams
  },

  methods: {
    handleClose() {
      this.dialogVisible = false
      this.$emit('close')
    },
    checkNumberChange(row, _row, _value, _oldValue, _key) {
      this.scanSum -= _oldValue
      this.scanSum += _value
    },
    control() {
      this.$refs.searchCode.focus()
    },
    //选择差异
    selectDiff() {
      if (this.newDataList.length) {
        return this.$message.error('请先删除当前款再选择');
      }
      this.inventoryDiffLisDialog = true
    },
    searchCode() {
      if (!this.newDataList.length) {
        this.$nextTick(() => {
          this.barCode = ''
        })
        return this.$message.error('请先选择盘点差异货品或手动添加')
      }
      // this.$nextTick(() => {
      //     this.$refs.searchCode.blur()
      // })
      if (!this.barCode) {
        this.$nextTick(() => {
          this.$refs.searchCode.focus()
        })
        return
      }
      let has = false
      let messageAudio = new Audio(require('@/assets/audio/scan_tip.mp3'))
      let errorAudio = new Audio(require('@/assets/audio/warning.mp3'))
      for (let i of this.newDataList[0].sizeInfoList) {
        if (i.barCode == this.barCode) {
          this.scanFlag ? i.rediskNum -= 1 : i.rediskNum += 1
          this.scanFlag ? this.scanSum -= 1 : this.scanSum += 1
          messageAudio.play()
          has = true
          break
        }
      }
      this.$nextTick(() => {
        this.barCode = ''
        this.$refs.searchCode.focus()
      })

      if (!has) {
        errorAudio.play()
        return this.$message.error('当前货号没有找到该条码')
      }
      // this.searchInNo = this.barCode
    },
    //确定选中的差异货号数据
    selectDiffData(e) {
      if (e.length) {
        this.fetchDetail(e[0].goodsNo)
      }
      this.inventoryDiffLisDialog = false
    },
    actionClick(event, row, rIndex) {
      this.$confirm('是否删除这条记录', '提示').then(res=>{
        this.newDataList.splice(rIndex, 1)
        this.scanSum = 0
      }).catch(()=>{})
    },
    addDirectly() {
      if(!this.manuallyAdd){
        return this.$message({type: 'error', message: '请输入货号/简码/条码',duration:2000,showClose:true})
      }
      if (this.newDataList.length) {
        this.manuallyAdd = ''
        return this.$message({type: 'error', message: '请先删除当前款再选择',duration:2000,showClose:true})
      }
      this.fetchDetail(this.manuallyAdd)
    },
    fetchDetail(ele) {
      this.loading = true
      let reqData = {
        recordInfoId: this.modalParams.id,
        keyword: ele
      }
      queryInventoryDifferenceGoodsInfo(
          reqData
      )
          .then((data) => {
            this.loading = false
            this.$nextTick(() => {
              // 判断是否是第一次渲染或者是超尺码拣货就进行赋值渲染
              // if (this.first || this.isOverScan) {
              //     this.newDataList = data.goodsInfoList || []
              //     this.first = false
              // }
              // //一键验收直接赋值 会闪动
              // if (type == 'refresh') {
              //     this.newDataList = data.goodsInfoList || []
              // }
              // this.updataList = data.goodsInfoList
              if (Object.keys(data).length !== 0) {
                for (let i of data.sizeInfoList) {
                  i.rediskNum = 0
                  i.value = i.size
                }
              }
              this.newDataList = [data] || []
              this.manuallyAdd = ''
            })
          })
          .catch((err) => {
            this.loading = false
            this.$message({
              type: 'error',
              message: err,
              duration: 2000,
              showClose: true
            })
          })
    },
    submitDetail() {
      if(!this.newDataList.length) return this.$message({type: 'error', message: '您还未添加货品，请先添加',duration:2000,showClose:true})
      const goodsInfoList = []
      for (let i of this.newDataList[0].sizeInfoList) {
        let data = {}
        data.skuInfoId = i.skuInfoId
        data.number = i.rediskNum
        data.oldNumber = i.inventoryNumber || '0'
        goodsInfoList.push(data)
      }
      if (this.scanSum == 0) return this.$message({type: 'error', message: '本次重盘数不能为0',duration:2000,showClose:true})
      this.loading = true
      let reqData = {
        recordInfoId: this.modalParams.id,
        goodsInfoList
      }
      addInventoryDifferenceAgainInventoryInfo(reqData).then((data) => {
        this.loading = false
        this.$message.success('提交成功')
        this.dialogVisible = false
        this.$emit('close', true)
      }).catch((err) => {
        this.loading = false
        this.$message({
          type: 'error',
          message: err,
          duration: 2000,
          showClose: true
        })
      })
    },
  }
}
</script>
<style lang='scss'>
.singleRedisc-class {
  .singleRedisc-title {
    display: flex;
    align-items: center;
    span{
      margin-left: 15px;
    }
  }

  .search-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 20px;
    .search-card {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .search {
        display: flex;
        align-items: center;
        padding: 0px 15px;
        height: 50px;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        .search-line{
          margin-left: 8px;
        }
        .search-btn {
          width: 49px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          background: #409eff;
          border-radius: 4px;
          font-size: 12px;
          color: #fff;
          cursor: pointer;
        }

        input {
          border: none;
          font-size: 20px;
          width: 286px;
          background: none;
          outline: none;
          color: #067cf2;
          border: none;
        }

        input::-webkit-input-placeholder {
          color: #067cf2;
        }

        input:focus {
          color: #c56209;
        }

        input:focus::-webkit-input-placeholder {
          color: #c56209;
        }
      }
    }

    .manuallyAdd-class {
      input {
        border-radius: 0px;
      }
    }
  }
}


</style>