<template>
    <section class="component">
        <el-dialog
            class="dialog"
			      :top="top + 'vh'"
            :width="width" 
            :append-to-body="true" 
            :show-close="false" 
            :close-on-click-modal="closeOnClickModal" 
            :close-on-press-escape="closeOnPressEscape" 
            :visible.sync="visible"
            @close="close"
            >
            <div slot="title" class="title row align-center space-between" :style="{background: bgColor}">
                <div class="row align-center">
                    <p class="row align-center">
                        <span class="title-icon"></span>
                        <span class="title-text">{{title}}</span>
                    </p>
                    <div class="row align-center descript" v-if="descript">(
                        整单备注：<p>{{descript}}</p>
                    )</div>
                    <div class="row align-center descript" v-if="timerchange">
                        只显示待支付和已支付{{timerchange}}分钟内的订单
                    </div>
                    <slot name="change"></slot>
                </div>
                <slot name="close"></slot>
            </div>
            <slot name="content"></slot>
        </el-dialog>
    </section>
</template>

<script>
export default {
    props: {
        bgColor: {
            type: String,
            default: '#F5F7FA'
        },
        title: {
            type: String
        },
        dialogVisible: {
            type: Boolean,
            default: false
        },
        closeOnClickModal: {
            type: Boolean,
            default: false
        },
        closeOnPressEscape: {
            type: Boolean,
            default: true
        },
        width: {
            type: String,
            default: '50%'
        },
		top: {
			type: String,
			default: () => '20'
		},
        descript: {
            type: String,
            default: () => ''
        },
        timerchange: {
            type: String,
            default: () => ''
        }
    },
    data(){
        return{
            visible: false,
        }
    },
    created() {
        this.visible = this.dialogVisible
    },
    methods: {
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss" scoped>
.title{
    height:40px;
    padding: 0 20px;
    border-radius:6px 6px 0 0;
    font-family: 'font-Regular';
    .title-icon{
        width:4px;
        height:20px;
        background:#067CF2;
        border-radius:2px;
        margin-right:10px;
    }
    .title-text{
        color: #333;
    }
    .descript{
        margin-left: 15px;
        margin-right: 8px;
        p{
            width: auto;
            white-space: nowrap;
            overflow: hidden;
            max-width: 380px;
            text-overflow: ellipsis;
        }
    }
}

</style>
<style>
.dialog .el-dialog__header{
    padding: 0 !important;
}
.dialog .el-dialog__body{
    padding: 0;
}
</style>