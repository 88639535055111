import request from '@/libs/http'

export const addRecordInfo = params => request.post('/wms/shop/add-inventory-record-detail-info', params) //盘点记录添加明细
export const queryGoodsInfo = params => request.post('/wms/shop/query-goods-info', params) //查询货品信息
export const queryCurgoodsInfo = params => request.post('/wms/shop/query-goods-info-color-and-size-info', params) //查询同一货号下的颜色和尺码信息
export const queryCurrentDetail = params => request.post('/wms/shop/query-current-detail', params) //查询当前的盘点记录
export const createRecordInfo = params => request.post('/wms/shop/create-inventory-record-info', params) //创建盘点记录
export const queryWarehouseAreaConfig = params => request.post('/wms/shop/query-warehouse-area-config', params) //查询店铺仓库分区配置
export const queryMarkedWords = params => request.post('/wms/shop/get-inventory-marked-words', params) //查询盘点提示语
export const queryGoodsCategoryTree = params => request.post('/erp/query-goods-category-info-menu-tree', params) //查询分类信息树形菜单
export const queryRecordDetail = params => request.post('/wms/shop/query-inventory-record-detail-info-list', params) //查询店铺盘点单明细信息
export const changeListRemark = params => request.post('/wms/shop/update-inventory-record-details-info', params) //修改盘点记录的备注
export const changeDetailState = params => request.post('/wms/shop/change-record-detail-state', params) //修改盘点明细记录
export const inventoryRecordNo = params => request.post('/wms/shop/query-inventory-record-no-detail', params) //查询盘点明细详情（条码记录）
export const changeRecordState = params => request.post('/wms/shop/change-record-state', params) //修改盘点记录状态
export const generateDiffer = params => request.post('/wms/shop/query-inventory-difference-info-list', params) //查询差异详情列表
export const addDifferenceUpdate = params => request.post('/wms/shop/add-inventory-difference-update-log', params) //添加盘点差异修改记录
export const queryDiffColorArea = params => request.post('/wms/shop/query-different-color-and-area-info', params) //查询同一盘点下差异单中的颜色、区域相关信息
export const queryDiffSizeNum = params => request.post('/wms/shop/query-different-size-number-info', params) //查询差异中的尺码数量信息
export const queryStockApprove = params => request.post('/wms/approval-center/query-inventory-apply-info', params) //查询盘点过账审批信息详情
export const queryNoAnalysis = params => request.post('/wms/shop/query-inventory-record-info-list', params) //查询店铺盘点单信息
export const queryChangeList = params => request.post('/wms/shop/query-inventory-difference-update-log-info', params) //查询差异修改记录货品详情
export const revocationUpdate = params => request.post('/wms/shop/revocation-inventory-difference-update-log', params) //撤回差异修改记录
export const queryDeptInventory = params => request.post('/wms/shop/query-console-inventory-record-no-analysis', params) //控制台查询店铺盘点记录
export const queryRecordDetails = params => request.post('/wms/shop/query-inventory-record-info-detail-list', params) //查询单据明细信息列表
export const queryRecordGoods = params =>
  request.post('/wms/shop/query-inventory-record-detail-goods-info-list', params) //查询单据明细货品信息列表
export const queryStockTotalList = params =>
  request.post('/wms/shop/query-inventory-record-detail-goods-info-list-by-record-id', params) //通过盘点单查询单据明细货品信息列表
export const queryStockDiffersList = params =>
  request.post('/wms/shop/query-wms-inventory-difference-info-list', params) //查询差异详情列表
export const queryDifferColorArea = params => request.post('/wms/shop/query-wms-different-color-and-area-info', params) //查询同一盘点下差异单中的颜色、区域相关信息
export const queryDifferSizeNum = params => request.post('/wms/shop/query-inventory-different-size-number-info', params) //查询差异中的尺码数量信息
export const queryInventoryDifferenceGoodsNoList = params =>
  request.post('/wms/shop/query-inventory-difference-goods-no-list', params) //查询差异货品信息列表
export const queryInventoryDifferenceGoodsInfo = params =>
  request.post('/wms/shop/query-inventory-difference-goods-info', params) //查询差异中的尺码数量信息
export const addInventoryDifferenceAgainInventoryInfo = params =>
  request.post('/wms/shop/add-inventory-difference-again-inventory-info', params) //新增店铺单款重盘信息
export const queryDifferDetailList = params => request.post('/wms/shop/query-difference-detail-list', params) //查询差异明细货品列表
export const queryDifferDetailInfo = params => request.post('/wms/shop/query-difference-detail-info', params) //查询差异明细信息
export const queryGoodsByBarcode = params => request.post('/wms/shop/query-goods-by-barcode', params)
export const addInventoryRecordId = params => request.post('/wms/shop/add-wms-inventory-record-detail-info', params) //添加盘点明细单信息
export const judgmentInventoryApply = params => request.post('/wms/shop/judgment-inventory-apply', params) //判断是否有盘点申请记录
export const createInventoryApply = params => request.post('/wms/shop/create-inventory-apply', params) //创建盘点申请记录
