<template>
  <section>
    <Zdialog
        width="90%"
        ref="zdialog"
        :title="'【盘点差异】' + modalParams.inventoryName"
        zDialogRef="z-dialog1"
        :show.sync="drawerVisible"
        :isConfirmClose="false"
        :isFooter="false"
        :modal="false"
        :hasBodyClass="false"
        :hasMinBtn="false"
        @close="close"
        @minimizeVal="minimizeVal"
        class="stockZdialog">
        <section slot="body">
          <div class="row">
            <section class="record-main">
              <searchForm :options="options" @searchHandel="searchHandel"></searchForm>
              <div class="differ-table">
                <div class="row align-center" v-if="totalObj" style="font-weight: bold;">
                  <div>汇总：</div>
                  <div>盘点总数：<span style="color:#F8475F;">{{totalObj.inventoryNumber || '0'}}</span></div>
                  <div>库存总数：<span style="color:#F8475F;">{{totalObj.stockNumber || '0'}}</span></div>
                  <div>差异总数：<span style="color:#F8475F;">{{totalObj.differenceNumber || '0'}}</span></div>
                  <div>差异总金额：<span style="color:#F8475F;">￥{{totalObj.differencePrice || '0.00'}}</span></div>
                  <div>赔付总金额：<span style="color:#F8475F;">￥{{totalObj.compensatePrice || '0.00'}}</span></div>
                </div>
                <grid-manager
                    v-if="gridOption.ajaxData"
                    :option="gridOption"
                    ref="grid"
                ></grid-manager>
              </div>
            </section>
            <colorSize
                ref="colorSize"
                v-if="queryInfoRow"
                :queryInfoRow="queryInfoRow"
                :queryType="queryForm.differState"
                :chooseAreaId="queryForm.areaConfigurationId"
                :invoiceState="modalParams.state"
                @changeValue="getChangeData">
            </colorSize>
          </div>
        </section>
        <section slot="titleRight" class="dialog-top">
            <div class="btnTop"></div>
        </section>
    </Zdialog>
    <changeDialog v-if="changeVisible" :fetchId="queryInfoRow.differenceUpdateId" @close="closeChage"></changeDialog>
  </section>
</template>

<script>
import Zdialog from '@/components/ZDialog/index.vue'
import searchForm from '@/components/searchForm.vue'
import colorSize from "../stockDifferList/components/colorSize"
import changeDialog from '../stockDifferList/components/changeDialog'
import {
  queryGoodsCategoryTree,
  queryStockDiffersList,
  queryWarehouseAreaConfig
} from '@/libs/http/modules/stock'
export default {
  props: {
    modalParams: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    Zdialog,
    colorSize,
    changeDialog,
    searchForm
  },
  data() {
    return {
      loading: false,
      drawerVisible: false,
      options: {
        searchItem: [
          {
            id: '2',
            type: 'select',
            size: 'small',
            clearable: true,
            placeholder: '请选择',
            label: '差异类型',
            value: '',
            resField: 'queryType',
            list: [
              { name: "有差异", value: "1" },
              { name: "无差异", value: "2" },
            ]
          },
          {
            id: 'hasUpdate',
            type: 'select',
            size: 'small',
            clearable: true,
            placeholder: '请选择',
            label: '是否修改',
            value: '',
            resField: 'hasUpdate',
            list: [
              { name: "是", value: "Y" },
              { name: "否", value: "N" },
            ]
          },
          {
            id: '3',
            type: 'goodsNoSelector',
            size: 'small',
            clearable: true,
            modelKey: 'goodsNo',
            placeholder: '请输入货号模糊查询',
            label: '货号',
            value: '',
            resField: 'goodsNo'
          },
          {
            id: '4',
            type: 'cascader',
            size: 'small',
            clearable: true,
            placeholder: '请选择',
            label: '款式分类',
            props: {
              multiple: true,
              checkStrictly: true,
              emitPath: false,
              value: 'id',
              label: 'name',
              children: 'pojoList',
            },
            showAllLevels: false,
            value: '',
            resField: 'categoryId1',
            list: []
          },
          {
            id: '5',
            type: 'cascader',
            size: 'small',
            clearable: true,
            placeholder: '请选择',
            label: '风格分类',
            props: {
              multiple: true,
              checkStrictly: true,
              emitPath: false,
              value: 'id',
              label: 'name',
              children: 'pojoList',
            },
            value: '',
            resField: 'categoryId2',
            list: []
          },
          {
            id: '7',
            type: 'cascader',
            size: 'small',
            clearable: true,
            placeholder: '请选择',
            label: '时间分类',
            props: {
              multiple: true,
              checkStrictly: true,
              emitPath: false,
              value: 'id',
              label: 'name',
              children: 'pojoList',
            },
            showAllLevels: false,
            value: '',
            resField: 'categoryId3',
            list: []
          },
          // {
          //   id: '6',
          //   type: 'select',
          //   size: 'small',
          //   clearable: true,
          //   placeholder: '请选择',
          //   label: '区域',
          //   value: '',
          //   resField: 'areaId',
          //   list: []
          // }
        ],
        // 确认按钮
        sureBtnVisable: true,
        sureBtnType: 'primary',
        sureBtnSize: 'small',
        sureBtnText: '查询',
        restBtnVisable: true,
        restBtnType: '',
        restBtnSize: 'small',
        restBtnText: '重置',
      },
      queryForm: {},
      changeVisible: false,
      changeList: [],
      queryInfoRow: null,
      queryIndex: '',
      gridOption: {
        gridManagerName: "stockDifferList",
        height: '72vh',
        firstLoading: true,
        isCombSorting: false,
        supportCheckbox: false,
        lineHeight: '30px',
        autoOrderConfig: {
          width: 50,
          fixed: undefined
        },
        checkboxConfig: {
          useRowCheck: true,
          fixed: 'left'
        },
        useTrFocus: true,
        supportAjaxPage: true,
        pageSize: 500,
        sizeData: [100, 200, 500, 1000],
        disableBorder: false,
        disableLine: false,
        columnData: [
          // {
          //   key: 'areaName',
          //   text: '区域',
          //   width: '100px'
          // },
          {
            key: 'goodsNo',
            text: '货号',
            width: '120px',
            template: () => {
              return `
                  <div :style="{color: row.updateState === '1' ? '#FF9000 !important' : row.updateState === '2' ? '#44D169 !important' : row.updateState === '3' ? 'red !important': ''}">{{row.goodsNo}}</div>
              `
            }
          },
          {
            key: 'goodsName',
            text: '货品名称',
            width: '150px'
          },
          {
            key: 'inventoryNumber',
            text: '盘点数量',
            sorting: '',
            width: '100px'
          },
          {
            key: 'stockNumber',
            text: '库存数量',
            sorting: '',
            width: '100px'
          },
          {
            key: 'differenceNumber',
            text: '差异数量',
            sorting: '',
            width: '100px',
            template: () => {
              return `
                <div :style="{color: row.differenceNumber != 0 ? 'red' : ''}">{{row.differenceNumber}}</div>
              `
            }
          },
          {
            key: 'retailPrice',
            text: '当前销售价',
            sorting: '',
            width: '120px'
          },
          {
            key: 'tagPrice',
            text: '价格',
            sorting: '',
            width: '100px'
          },
          {
            key: 'inventoryPrice',
            text: '盘点金额',
            sorting: '',
            width: '100px'
          },
          {
            key: 'compensatePrice',
            text: '赔付金额',
            sorting: '',
            width: '100px',
            template: () => {
              return `
                <div :style="{color: row.compensatePrice != 0 ? 'red' : ''}">{{row.compensatePrice}}</div>
              `
            }
          },
          {
            key: 'typeCategory1',
            text: '一级款式分类'
          },
          {
            key: 'typeCategory2',
            text: '二级款式分类'
          },
          {
            key: 'styleCategory1',
            text: '一级风格分类'
          },
          {
            key: 'styleCategory2',
            text: '二级风格分类'
          },
          {
            key: 'timeCategory1',
            text: '一级时间分类'
          },
          {
            key: 'timeCategory2',
            text: '二级时间分类'
          }
        ],
        ajaxData: this.fetch,
        ajaxError: this.ajaxError,
        summaryHandler: (data) => {
          let list = [
            'inventoryNumber',
            'stockNumber',
            'differenceNumber',
            'compensatePrice'
          ]
          let obj = {
            goodsNo: '本页汇总'
          }
          list.forEach((key) => {
            obj[key] = data.reduce((c, R) => c + Number(R[key] || 0), 0)
          })
          return obj
        },
        rowClick: (row, rowIndex) => {
          this.showINfo(row, rowIndex);
          row.highlight = true;
        }
      },
      totalObj: null,
      pageTotal: null
    };
  },
  created() {
    this.drawerVisible = true;
    this.fetchQuerytree('0');
    this.fetchQuerytree('1');
    this.fetchQuerytree('2');
    this.fetchArea();
  },
  methods: {
    close() {
      this.$emit('close', ['close', this.modalParams])
    },
    minimizeVal(e) {
      //是否是最小化，是的话就传给父组件
      if (e) {
        this.$emit('close', ['', this.modalParams])
      }
    },
    isMinimize() {
      this.$refs['zdialog'].minimize()
    },

    fetchQuerytree(type){
      queryGoodsCategoryTree({
        queryType: type
      }).then(data=>{
        if (data && data.length) {
          if (type === '0') {
            this.options.searchItem[3].list = this.recursiveFilter(data);
          }
          if (type === '1') {
            this.options.searchItem[4].list = this.recursiveFilter(data);
          }
          if (type === '2') {
            this.options.searchItem[5].list = this.recursiveFilter(data);
          }
        }
      }).catch(error=>{})
    },
    recursiveFilter(arr) {
      const data = arr;
      data.forEach((item) => {
        if (item.pojoList && item.pojoList.length) {
          this.recursiveFilter(item.pojoList);
        } else {
          delete item.pojoList;
        }
      });
      return data;
    },
    fetchArea(){
      queryWarehouseAreaConfig().then(data => {
        if(data && data.items){
          data.items.map(item=>{
            item.value = item.id;
          })
          this.options.searchItem[5].list = data.items || []
        }
      }).catch( err => {
        this.$message({type: 'error', message:err,duration:2000,showClose:true})
      })
    },
    searchHandel(e){
      this.queryForm = e;
      GridManager.refreshGrid(this.gridOption.gridManagerName, true);
    },
    fetch(params) {
      const that = this;
      return new Promise(async (resolve, reject) => {
        try {
          let reqData = {
            id: that.modalParams.id,
            sortData: params.sortData,
            page: params.pageData.cPage,
            pageSize: params.pageData.pSize
          }
          for(let key in that.queryForm){
            if(key && key !== 'categoryId1' && key !== 'categoryId2' && key !== 'categoryId3' && key !== 'goodsNo'){
              reqData[key] = that.queryForm[key]
            }
          }
          if(this.queryForm.goodsNo){
            reqData['goodsNo'] = this.queryForm.goodsNo.join(' ')
          }
          let sortObj = {}
          if(params.sortData){
            if(params.sortData.inventoryNumber){
              sortObj['totalInventoryNumber'] = params.sortData.inventoryNumber
            }
            if(params.sortData.stockNumber){
              sortObj['totalStockNumber'] = params.sortData.stockNumber
            }
            if(params.sortData.differenceNumber){
              sortObj['totalDifferenceNumber'] = params.sortData.differenceNumber
            }
            if(params.sortData.retailPrice){
              sortObj['totalRetailPrice'] = params.sortData.retailPrice
            }
            if(params.sortData.tagPrice){
              sortObj['totalTagPrice'] = params.sortData.tagPrice
            }
            if(params.sortData.inventoryPrice){
              sortObj['totalInventoryPrice'] = params.sortData.inventoryPrice
            }
            if(params.sortData.compensatePrice){
              sortObj['totalDifferencePrice'] = params.sortData.compensatePrice
            }
          }
          if(JSON.stringify(sortObj) !== "{}"){
            reqData['sortData'] = sortObj
          }
          let categoryIds1 = [];
          let categoryIds2 = [];
          let categoryIds3 = [];
          if(this.queryForm.categoryId1){
            categoryIds1 = this.queryForm.categoryId1
          }
          if(this.queryForm.categoryId2){
            categoryIds2 = this.queryForm.categoryId2
          }
          if(this.queryForm.categoryId3){
            categoryIds3 = this.queryForm.categoryId3
          }
          if(categoryIds1.length || categoryIds2.length || categoryIds3.length){
            reqData['categoryIds'] = [...categoryIds1, ...categoryIds2, ...categoryIds3]
          }
          let data = await queryStockDiffersList(reqData);
          this.totalObj = data.differenceCollectInfo
          this.pageTotal = data.pageDifferenceCollectInfo
          if(!that.queryInfoRow){
            that.queryInfoRow = data.items[0];
          }else{
              that.queryInfoRow = data.items[this.queryIndex]
          }
          const tableData = {
            data: data.items,
            totals: Number(data.totalItem) || 0,
          };
          resolve(tableData);
        }catch(e) {
          reject(e);
        }
      });
    },
    ajaxError(e){
      this.$message({type: 'error', message: e, duration: 2000, showClose: true})
    },
    showINfo(row, index) {
      this.queryInfoRow = row;
      this.queryIndex = index;
      this.$nextTick(() => {
        this.$refs.colorSize.fetch();
      });
    },
    getChangeData(e){
      if(!this.queryInfoRow.differenceUpdateId){
        this.queryInfoRow.differenceUpdateId = e;
        GridManager.refreshGrid(this.gridOption.gridManagerName);
      }else{
        this.changeVisible = true;
      }
    },
    closeChage(e){
      this.changeVisible = false;
      if(e){
        GridManager.refreshGrid(this.gridOption.gridManagerName);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.record-main {
  width: calc(100% - 400px);
  .differ-table {
    padding: 0 10px;
    margin: 0 10px;
    div div{
      margin-right: 20px;
    }
  }
}
</style>
