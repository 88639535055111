<template>
    <div class="app-container">
        <el-dialog
            v-dialogDrag
            :ref="zDialogRef"
            class="ZDialog"
            :modal="false"
            :lock-scroll="true"
            :title="title"
            :fullscreen="isfullscreen"
            :visible.sync="visible"
            :append-to-body="true"
            :show-close="false"
            :show="show"
            :top="top"
            :width="width"
            :close-on-click-modal="false"
            @close="handleClose"
            @mousedown.native="handleClickOutside($event)"
            :class="{ isminimize: isminimize }"
        >
            <!-- @dblclick.native="handleClickOutside($event) 双击关闭遮罩层然后最小化 -->
            <!-- @mouseup.native="handleClickOutside($event)" -->
            <div v-show="!isminimize" slot="title" class="medium" @dblclick="resetFull">
                <div class="lefts">
                    <span>{{ title }}</span>
                    <slot name="titleLeft"> </slot>
                </div>
                <div class="icons">
                    <slot name="titleRight"> </slot>
                    <i title="刷新" v-if="isRefresh" class="el-icon-refresh" style="transform: scale(1.4)" @click="refresh"></i>
                    <i title="缩小" class="el-icon-minus" style="font-size: 24px" @click="minimize"></i>
                    <i
                        title="放大"
                        :class="['iconfont', isfullscreen ? 'icon-reduce' : 'icon-amplify']"
                        style="font-size: 24px"
                        @click="IsFullscreen"
                    ></i>
                    <i title="关闭" class="el-icon-close" style="font-size: 24px" @click="closeDialog"></i>
                </div>
            </div>
            <div v-show="isminimize" slot="title" class="horn" @dblclick="resetFull">
                <div class="lefts">
                    <span>{{ title }}</span>
                </div>
                <div class="icons">
                    <i title="放大" class="el-icon-copy-document" style="font-size: 24px" @click="minimize"></i>
                </div>
                <i title="关闭" class="el-icon-close" style="font-size: 24px" @click="closeDialog"></i>
            </div>
            <div v-show="!isminimize" :class="[hasBodyClass ? 'dialogBody' : '']">
                <slot name="body" solt="body"> </slot>
            </div>
            <div v-show="!isminimize" class="dialogFooter" v-if="isFooter">
                <slot name="footer" solt="footer"> </slot>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isfullscreen: true, // 全屏
            isminimize: false, // 最小化
            visible: this.show // 隐藏弹窗
        }
    },
    props: {
        show: { type: Boolean, default: false },
        title: { type: String, default: '' },
        zDialogRef: { type: String, default: 'zDialog' },
        isFooter: { type: Boolean, default: false },
        width: { type: String, default: '450px' },
        top: { type: String, default: '8vh' },
        isConfirmClose: { type: Boolean, default: false }, //是否需要二次确认
        defaultFullscreen:{ type:Boolean,default:true},//是否开启全屏，默认是
        isRefresh:{ type:Boolean,default:false},//是否开启刷新，默认否
        hasBodyClass: {type:Boolean,default:true},//slot -> body是否有class样式
    },
    created() {
       this.isfullscreen = this.defaultFullscreen  
    },
    mounted() {
        // 绑定enter事件
        if (window.history && window.history.pushState) {
            history.pushState(null, null, document.URL)
            window.addEventListener('popstate', this.closeBack, false)
        }
    },
    destroyed() {
        window.removeEventListener('popstate', this.closeBack, false)
    },
    methods: {
        closeBack() {
            if (this.isConfirmClose) {
                this.$confirm('本次提交的内容将否会被保存，是否退出 ?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                    .then(() => {
                        // this.dialogVisible = false
                        this.$emit('close', false)
                    })
                    .catch(() => {})
            } else {
                this.$emit('update:show', false)
                this.$emit('close', false)
            }
        },
        // 最小化
        minimize() {
            this.isminimize = !this.isminimize
            // if (this.isfullscreen) this.isfullscreen = !this.isfullscreen
            this.$emit('minimizeVal', this.isminimize)
        },

        // 关闭弹窗
        closeDialog() {
            if (this.isConfirmClose) {
                this.$confirm('本次提交的内容将不会被保存，是否退出 ?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                    .then(() => {
                        this.$emit('update:show', false)
                        // this.$emit('update:closeOnClickModal', false)
                        this.$emit('close', false)
                    })
                    .catch(() => {})
            } else {
                this.$emit('update:show', false)
                this.$emit('close', false)
                // this.$emit('update:closeOnClickModal', false)
            }
        },
        // 全屏
        IsFullscreen() {
            this.isfullscreen = !this.isfullscreen
            if (this.isfullscreen) this.$emit('isfullscreen')
        },
        resetFull() {

            this.isfullscreen = !this.isfullscreen
           
        },
        handleClose(action) {
            if (this.isConfirmClose) {
                this.$confirm('确定关闭?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                    .then(() => {
                        this.$emit('update:show', false)
                    })
                    .catch(() => {})
            } else {
                this.$emit('update:show', false)
            }
        },
        //点击到外部
        handleClickOutside(e) {
            let isOutside = e.target.classList[0]
            //点击遮罩层实现最小化
            if (isOutside === 'el-dialog__wrapper') {
                this.minimize()
            }
        },
         //刷新按钮
         refresh() {
            this.$emit('refresh')
        }
    },
    watch: {
        visible(val) {
            if (val) {
                const el = this.$refs[this.zDialogRef].$el.querySelector('.el-dialog')
                el.style.left = 0
                el.style.top = 0
            }
        },
        show: {
            immediate: true,
            handler(show) {
                this.visible = this.show
            }
        }
    },
    directives: {
        dialogDrag: {
            bind(el, binding, vnode, oldVnode) {
                const dialogHeaderEl = el.querySelector('.el-dialog__header')
                const dragDom = el.querySelector('.el-dialog')
                // 重点！！！！解决打开多个弹窗后，弹窗失去拖动功能
                // .el-dialog样式
                dragDom.style.pointerEvents = 'auto'
                dialogHeaderEl.style.cursor = 'move'

                // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
                const sty = dragDom.currentStyle || window.getComputedStyle(dragDom, null)
                // const fixedX =
                // const fixedY =
                dialogHeaderEl.onmousedown = (e) => {
                    // 判断当前是否为全屏状态
                    const path = event.path || (event.composedPath && event.composedPath())
                    const isFull = path.find((s) => {
                        if (s.className === undefined) {
                            return false
                        } else {
                            return s?.className?.indexOf('is-fullscreen') > -1
                        }
                    })
                    if (isFull !== undefined) {
                        return
                    }
                    const isMinix = path.find((s) => {
                        if (s.className === undefined) {
                            return false
                        } else {
                            return s?.className?.indexOf('isminimize') > -1
                        }
                    })
                    if (isMinix !== undefined) {
                        return
                    }
                    // 鼠标按下，计算当前元素距离可视区的距离
                    const disX = e.clientX - dialogHeaderEl.offsetLeft
                    const disY = e.clientY - dialogHeaderEl.offsetTop

                    // 获取到的值带px 正则匹配替换
                    let styL, styT

                    // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
                    if (sty.left.includes('%')) {
                        styL = +document.body.clientWidth * (+sty.left.replace(/\%/g, '') / 100)
                        styT = +document.body.clientHeight * (+sty.top.replace(/\%/g, '') / 100)
                    } else {
                        styL = +sty.left.replace('px', '')
                        styT = +sty.top.replace('px', '')
                    }

                    document.onmousemove = function (e) {
                        // 通过事件委托，计算移动的距离
                        const l = e.clientX - disX
                        const t = e.clientY - disY
                        // 移动当前元素
                        dragDom.style.left = `${l + styL}px`
                        dragDom.style.top = `${t + styT}px`
                        // const dom = e.path.find(s => s.querySelector('.el-dialog')).children[0]
                        //
                        // if (dom.offsetTop < 0) {
                        //   dragDom.style.top = `0px`
                        // }
                        // if (dom.offsetLeft < 0) {
                        //   dragDom.style.left = `0px`
                        // }

                        // 将此时的位置传出去
                        // binding.value({x:e.pageX,y:e.pageY})
                    }

                    document.onmouseup = function (e) {
                        const dragDom = el.querySelector('.el-dialog')
                        const offsetLeft = dragDom.offsetLeft
                        const offsetTop = dragDom.offsetTop
                        const left = Number(dragDom.style.left.replace('px', ''))
                        const top = Number(dragDom.style.top.replace('px', ''))
                        const windowWidth = window.innerWidth
                        const windowHeight = window.innerHeight - 50
                        const offsetRight = offsetLeft + dragDom.offsetWidth - windowWidth
                        const offsetBottom = offsetTop + dragDom.offsetHeight - windowHeight
                        if (offsetLeft < 0) {
                            dragDom.style.left = left - offsetLeft + 'px'
                        }
                        if (offsetTop < 0) {
                            dragDom.style.top = top - offsetTop + 'px'
                        }
                        if (offsetRight > 0) {
                            dragDom.style.left = left - offsetRight + 'px'
                        }
                        if (offsetBottom > 0) {
                            dragDom.style.top = top - offsetBottom + 'px'
                        }
                        document.onmousemove = null
                        document.onmouseup = null
                    }
                }
            }
        }
    }
}
</script>
<style lang="scss">
.el-dialog {
    margin-top: 8vh;
}

.no_select {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    -khtml-user-select: none;
    /* Konqueror */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently */
}

.isminimize {
    display: none;
    left: 20px;
    bottom: 20px;
    top: auto;
    right: auto;
    box-shadow: 2px 2px 7px #ccc;
    overflow: hidden;

    .el-dialog {
        margin: 0 !important;
        width: 240px !important;
        height: 43px;
        top: 0 !important;
        left: 0 !important;
    }

    .el-dialog__header {
        cursor: auto !important;

        .el-dialog__headerbtn {
            display: none;
        }
    }

    .dialogFooter {
        position: absolute;
        bottom: 0;
    }
}

.ZDialog {
    .is-fullscreen {
        width: 100% !important;
        left: 0 !important;
        top: 0 !important;
        margin-top: 0 !important;
        overflow: hidden;
        position: relative;

        .el-dialog__header {
            cursor: auto !important;
        }

        .el-dialog__body {
            height: 100%;
            padding: 0px !important;

            .dialogBody {
                height: 100% !important;
                max-height: none !important;
                padding-bottom: 120px !important;
            }
        }

        .dialogFooter {
            position: absolute;
            bottom: 0;
            width: 100%;
            z-index: 20;
            background: #fff;
        }
    }

    .el-dialog {
        .el-dialog__header {
            width: 100%;
            padding: 10px 10px 10px 20px !important;
            display: flex;
            border-bottom: 1px solid #ccc;
            @extend .no_select;
            cursor: auto;

            .medium {
                width: 100%;
                height: 100%;
                display: flex;

                div {
                    flex: 1;
                }

                .lefts {
                    margin-top: 3px;

                    span {
                        text-align: left;
                        // font-size: 16px;
                        // color: #606266;
                        font-size: 18px;
                        color: #333;
                        font-weight: bold;
                    }
                }

                .icons {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    i {
                        color: #5f6368;
                        display: inline-block;
                        font-size: 18px !important;
                        padding: 3px;
                        margin: 0 5px;
                    }

                    i:hover {
                        background: #dcdfe6;
                        cursor: pointer;
                    }

                    .el-icon-close:hover {
                        background: #f00;
                        color: #fff;
                    }
                }
            }

            .horn {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;

                div {
                    i {
                        color: #5f6368;
                        font-size: 20px !important;
                    }
                }

                .lefts {
                    flex: 4;
                    margin-top: 3px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    span {
                        font-size: 16px;
                        color: #606266;
                    }
                }

                .icons {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    i {
                        color: #5f6368;
                        font-size: 18px !important;
                        display: block;
                        padding: 3px;
                    }

                    i:hover {
                        background: #dcdfe6;
                        cursor: pointer;
                    }

                    .el-icon-close:hover {
                        background: #f00;
                        color: #fff;
                    }
                }

                // .centers {
                //   flex: 1;
                // }
                // .rights {
                //   flex: 1;
                // }
                i:hover {
                    cursor: pointer;
                    color: #000;
                }
            }

            .el-dialog__headerbtn {
                top: 0;
                font-size: 24px;
            }
        }

        .el-dialog__body {
            padding: 0px !important;

            .dialogBody {
                max-height: calc(80vh - 50px);
                // box-shadow: inset 0px -2px 10px 1px #b0b3b2;
                overflow: auto;
                padding: 20px 0;

                &::-webkit-scrollbar {
                    width: 4px;
                    height: 8px;
                }

                &::-webkit-scrollbar-thumb {
                    background: transparent;
                    border-radius: 4px;
                }

                &:hover::-webkit-scrollbar-thumb {
                    background: hsla(0, 0%, 53%, 0.4);
                }

                &:hover::-webkit-scrollbar-track {
                    background: hsla(0, 0%, 53%, 0.1);
                }
            }

            .dialogFooter {
                padding: 10px 20px;
                border-top: 1px solid #ccc;
                text-align: right;
            }
        }
    }

    .el-dialog__wrapper {
        pointer-events: none;
    }

    .ZDialog {
        // display: flex;
        // justify-content: center;
        .el-select {
            width: 100%;
        }

        .el-date-editor {
            width: 100%;
        }
    }
}
</style>
